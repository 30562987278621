import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import moment from "moment";
import { toast } from "react-toastify";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import ApiService from "../../utils/Api";
import {
  GET_CATEGORIES_V1,
  GET_ESTIMATE_TRIP_FARE,
  GET_KIOSK_ESTIMATE_TRIP_FARE,
  SAVE_KIOSK_TRIP,
  SEND_NOTIFICATION_TO_NEARBYDRIVER,
  GET_TRIPS,
  UPDATE_TRIP,
  KioskSMS,
} from "../../utils/Endpoint";
import { Detailpage } from "../popup/detail";
import { SendMessageDuringTrip } from "../popup/tripUserDetail";

const KioskHotelBookingForm = () => {
  const username = sessionStorage.getItem("name");
  const userId = sessionStorage.getItem("userid");
  const cityId = sessionStorage.getItem("city_id");
  const currency = sessionStorage.getItem("currency");
  const apiKey = sessionStorage.getItem("key");
  const fromAutocompleteRef = useRef();
  const toAutocompleteRef = useRef();
  const [fromLocationAddress, setFromLocationAddress] = useState({
    address: sessionStorage.getItem("u_address"),
    lat: sessionStorage.getItem("u_lat"),
    lng: sessionStorage.getItem("u_lng"),
    selected: true,
  });
  const [toLocationAddress, setToLocationAddress] = useState({
    address: "",
    lat: "",
    lng: "",
    selected: false,
  });
  const [distanceMatrix, setDistancematrix] = useState({
    distance: 0,
    duration: 0,
    calculatedDistance: 0,
    calculatedDirection: 0,
  });
  const [fareMatrix, setFareMatrix] = useState({
    basePrice: 0,
    baseFarePerKm: 0,
    farePerKm: 0,
    farePerMin: 0,
    catBaseIncludes: 0,
    kioskPayAmt: 0,
    kioskOwnerComm: 0,
    kioskUserComm: 0,
  });
  const [estData, setEst] = useState(); // Replace with actual tripId state

  const [categories, setCategories] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showCategory, setShowCategory] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(""); // To store country code
  const [name, setName] = useState("");

  const [estimatedPriceList, setEstimatedPriceList] = useState([]);
  const [detailCat, setDetailCat] = useState([]);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [termsCheck, setTermsCheck] = useState(true);
  const [trip_terminate, setTrip_Terminate] = useState(() => {
    const savedValue = localStorage?.getItem("trip_terminate");
    return savedValue !== null ? JSON.parse(savedValue) : false;
  });
  const [tripDetails, setTripDetail] = useState(() => {
    const savedTripDetails = localStorage.getItem("tripDetails");
    return savedTripDetails ? JSON.parse(savedTripDetails) : {}; // Default to empty object
  });


  var user = {
    p_phone: phone,
    p_name: name,
  };
  var country_code;
  var ph_number;

  const setUserDetail = (phone, name) => {
    const parsedNumber = parsePhoneNumberFromString(`+${phone}`);
    if (parsedNumber && parsedNumber.countryCallingCode) {
      ph_number = phone;
      country_code = parsedNumber.countryCallingCode;
    }
    user = {
      p_phone: phone,
      p_name: name,
    };
  };
  const [UserLocationAddress, setUserLocationAddress] = useState({
    address: sessionStorage.getItem("u_address"),
    lat: sessionStorage.getItem("u_lat"),
    lng: sessionStorage.getItem("u_lng"),
    selected: true,
  });

  navigator.geolocation.getCurrentPosition(
    (position) => {
      const { latitude, longitude } = position.coords;
      setUserLocationAddress((prev) => ({
        ...prev,
        lat: latitude,
        lng: longitude,
      }));
    },
    (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.error("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          console.error("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          console.error("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          console.error("An unknown error occurred.");
          break;
      }
    }
  );

  const onPhoneChange = (phone) => {
    // Parse the phone number to get the country code
    const parsedNumber = parsePhoneNumberFromString(`+${phone}`);
    if (parsedNumber && parsedNumber.countryCallingCode) {
      setCountryCode(parsedNumber.countryCallingCode);
      setPhone(phone);
    }
  };

  // Update localStorage whenever tripDetails changes
  useEffect(() => {
    localStorage.setItem("tripDetails", JSON.stringify(tripDetails));
  }, [tripDetails]);

  useEffect(() => {
    localStorage.setItem("trip_terminate", JSON.stringify(trip_terminate));
  }, [trip_terminate]);
  const [errors, setErrors] = useState({});
  const [route, setRoute] = useState("");
  const navigate = useNavigate();
  const [tripId, setTripId] = useState(null); // Replace with actual tripId state
  const [driverId, setDriverId] = useState(null);
  const onChangeFromLocationAddress = (address) => {
    setFromLocationAddress({ address: address, selected: false });
    if (errors.fromLocation)
      setErrors((prev) => ({ ...prev, fromLocation: "" }));
  };

  const handleFromLocationSelect = async (address, placeId) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=AIzaSyAmhUdME8AnXNL9kooZPuxNcvwOlzaeI7k`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.results && data.results[0].geometry) {
          const { lat, lng } = data.results[0].geometry.location;
          console.log(data.results[0].formatted_address, lat, lng);

          setFromLocationAddress({
            address: data.results[0].formatted_address,
            lat: lat,
            lng: lng,
            selected: true,
          });
          console.log("Latitude:", lat, "Longitude:", lng);
        } else {
          console.error("No geometry data found:", data);
        }
      })
      .catch((error) => console.error("Error fetching place details:", error));
    // const result = await geocodeByAddress(address);
    // const formattedAddress = result[0].formatted_address;
    // const coordinates = await getLatLng(result[0]);
    // setFromLocationAddress({
    //   address: formattedAddress,
    //   lat: coordinates.lat,
    //   lng: coordinates.lng,
    //   selected: true,
    // });
    if (errors.fromLocation)
      setErrors((prev) => ({ ...prev, fromLocation: "" }));
  };
  //   setFromLocationAddress({ address: sessionStorage.getItem("u_address"), selected: false, lat: sessionStorage.getItem("u_lat"), lng: sessionStorage.getItem("u_lng") })

  const handleClearFromLocation = (address) => {
    setFromLocationAddress({ address: "", selected: false });
    if (errors.toLocation) setErrors((prev) => ({ ...prev, toLocation: "" }));
  };

  const onChangeToLocationAddress = (address) => {
    setToLocationAddress({ address, selected: false });
    if (errors.toLocation) setErrors((prev) => ({ ...prev, toLocation: "" }));
  };

  const handleToLocationSelect = async (address, placeId) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=AIzaSyAmhUdME8AnXNL9kooZPuxNcvwOlzaeI7k`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.results && data.results[0].geometry) {
          const { lat, lng } = data.results[0].geometry.location;
          console.log(data.results[0].formatted_address, lat, lng);

          setToLocationAddress({
            address: data.results[0].formatted_address,
            lat: lat,
            lng: lng,
            selected: true,
          });
          console.log("Latitude:", lat, "Longitude:", lng);
        } else {
          console.error("No geometry data found:", data);
        }
      })
      .catch((error) => console.error("Error fetching place details:", error));

    // const result = await geocodeByAddress(placeId);
    // const formattedAddress = result[0].formatted_address;
    // const coordinates = await getLatLng(result[0]);
    // setToLocationAddress({
    //   address: formattedAddress,
    //   lat: coordinates.lat,
    //   lng: coordinates.lng,
    //   selected: true,
    // });
    if (errors.toLocation) setErrors((prev) => ({ ...prev, toLocation: "" }));
  };

  const handleClearToLocation = () => {
    setToLocationAddress({ address: "", selected: false, lat: "", lng: "" });
    setEstimatedPriceList([]);
    setEstimatedPrice(0);
    setSelectedVehicle(null);
    setDistancematrix((prev) => ({
      ...prev,
      distance: 0,
      duration: 0,
      calculatedDistance: 0,
      calculatedDirection: 0,
    }));
  };

  const handleSelectedVehicle = (category) => () => {
    setSelectedVehicle(category);
    const selectedPackage = estimatedPriceList[category.category_id];
    setDetailCat(selectedPackage);
    setEstimatedPrice(selectedPackage?.kiosk_pay_amt);
    setFareMatrix({
      basePrice: selectedPackage?.trip_base_fare,
      baseFarePerKm: category?.cat_base_price,
      farePerKm: category?.cat_fare_per_km,
      farePerMin: category?.cat_fare_per_min,
      driverCommission: selectedPackage?.trip_driver_commision,
      companyCommission: selectedPackage?.trip_comp_commision,
      taxAmt: selectedPackage?.tax_amt,
      catBaseIncludes: selectedPackage?.cat_base_includes,
      kioskPayAmt: selectedPackage?.kiosk_pay_amt,
      kioskOwnerComm: selectedPackage?.kiosk_owner_comm,
      kioskUserComm: selectedPackage?.kiosk_user_comm,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!fromLocationAddress.address)
      formErrors.fromLocation = "*Enter Pickup Location";
    if (false && !toLocationAddress.address)
      formErrors.toLocation = "*Enter Destination Location";
    if (!termsCheck) formErrors.terms = "*Please accept terms and condition";
    if (!selectedVehicle) {
      formErrors.selectedVehicle = "*Please select a vehicle";
      toast.error("Please select category");
    }
    return formErrors;
  };

  const handleResetAddress = () => {
    setToLocationAddress({ address: "", selected: false, lat: "", lng: "" });
    setEstimatedPriceList([]);
    setEstimatedPrice(0);
    setSelectedVehicle(null);
    setDistancematrix((prev) => ({
      ...prev,
      distance: 0,
      duration: 0,
      calculatedDistance: 0,
      calculatedDirection: 0,
    }));

    setFromLocationAddress({
      address: sessionStorage.getItem("u_address"),
      lat: sessionStorage.getItem("u_lat"),
      lng: sessionStorage.getItem("u_lng"),
      selected: true,
    });
  };

  const handleCancelRide = async () => {
    try {
      // "Base Fare = Minimum fare + (Fare per km * Actual distance count ) + (Fare per min * Time(Estimated minutes))
      // Estimated Fare = Base Fare + GST amount applicable "

      // let jtf = (
      //   parseInt(fareMatrix.basePrice) +
      //   (fareMatrix.baseFarePerKm *
      //     (distanceMatrix.calculatedDistance - fareMatrix.catBaseIncludes) +
      //     fareMatrix.farePerMin * distanceMatrix.calculatedDirection)
      // ).toFixed(2);
      dayjs.extend(utc);
      dayjs.extend(timezone);
      let data = {
        trip_cancel: "Request",
        trip_id: sessionStorage.getItem("trip_id"),
        trip_status: "cancel",
        cancelled_by: "Rider",
        cancelled_details: {
          cancelled_location: tripDetails.address,
          cancelled_lat: tripDetails.lat,
          cancelled_lng: tripDetails.lng,
          cancelled_timestamp: dayjs().tz("GMT").format("YYYY-MM-DD HH:mm:ss"),
        },
      };

      const response = await ApiService.post(
        `${UPDATE_TRIP}?api_key=${apiKey}`,
        data
      );

      if (response.status === 200) {
        sessionStorage.removeItem("trip_id");

        handleResetAddress();
        setTrip_Terminate(false);
        getDistanceMatrixAndEstimatePriceList();
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  const [continueRide, setContinueRide] = useState(false);

  const handleBookNow = async () => {
    // if (!continueRide) {
    //   return;
    // }

    try {
      // event.preventDefault();
      const formErrors = validateForm();
      if (Object.keys(formErrors).length === 0) {
        let username = sessionStorage.getItem("phone");
        if (username === "" || username === null) {
          navigate("/login");
          return;
        }

        // "Base Fare = Minimum fare + (Fare per km * Actual distance count ) + (Fare per min * Time(Estimated minutes))
        // Estimated Fare = Base Fare + GST amount applicable "

        // let jtf = (
        //   parseInt(fareMatrix.basePrice) +
        //   (fareMatrix.baseFarePerKm *
        //     (distanceMatrix.calculatedDistance - fareMatrix.catBaseIncludes) +
        //     fareMatrix.farePerMin * distanceMatrix.calculatedDirection)
        // ).toFixed(2);
        dayjs.extend(utc);
        dayjs.extend(timezone);
        let data = {
          trip_date: dayjs().tz("GMT").format("YYYY-MM-DD HH:mm:ss"),
          cat_name: selectedVehicle.cat_name,
          trip_from_loc: fromLocationAddress.address,
          trip_to_loc: toLocationAddress.address,
          city_id: cityId, //process.env.REACT_APP_CITY_ID
          category_id: selectedVehicle.category_id,
          trip_currency: currency,
          trip_base_fare: fareMatrix.basePrice,
          trip_pay_amount: fareMatrix.kioskPayAmt,
          trip_driver_commision: fareMatrix.driverCommission,
          trip_comp_commision: fareMatrix.companyCommission,
          tax_amt: fareMatrix.taxAmt,
          trip_scheduled_pick_lat: fromLocationAddress.lat,
          trip_scheduled_pick_lng: fromLocationAddress.lng,
          trip_scheduled_drop_lat: toLocationAddress.lat,
          trip_scheduled_drop_lng: toLocationAddress.lng,
          trip_distance: distanceMatrix.calculatedDistance,
          trip_dunit: "km",
          trip_type: "normal",
          trip_total_time: distanceMatrix.calculatedDirection,
          seats: "1",
          trip_status: "request",
          is_share: "0",
          is_delivery: "0",
          is_return_details: true,
          trip_customer_details: JSON.stringify(user),
          kiosk_owner_id: sessionStorage.getItem("parent_id"),
          store_id: sessionStorage.getItem("parent_id"),
          kiosk_id: userId,
          kiosk_comm_json: fareMatrix.kioskOwnerComm,
          kiosk_usr_comm_json: fareMatrix.kioskUserComm,
          kiosk_type: sessionStorage.getItem("kiosk_ride_type"),
          kiosk_ride_type: sessionStorage.getItem("ride_type"),
          est_data: estData[selectedVehicle.category_id],
          req_route_data: route,
        };

        const response = await ApiService.post(
          `${SAVE_KIOSK_TRIP}?api_key=${apiKey}`,
          data
        );
        sessionStorage.setItem(
          "trip_id",
          response?.data?.resDecrypt?.response?.trip_id
        );
        const params = {
          is_share: "0",
          seats: "1",
          lat: fromLocationAddress.lat,
          lng: fromLocationAddress.lng,
          city_id: cityId, //process.env.REACT_APP_CITY_ID
          miles: distanceMatrix.calculatedDistance,
          trip_status: "request",
          trip_id: response.data.resDecrypt.response.trip_id,
          category_id: selectedVehicle.category_id,
        };

        if (response.status === 200) {
          const ride_type = sessionStorage.getItem("ride_type");

          if (ride_type?.toLowerCase() == "req") {
            const result = await ApiService.post(
              `${SEND_NOTIFICATION_TO_NEARBYDRIVER}?api_key=${apiKey}`,
              params
            );
            if (result.status === 200) {
              setTripDetail(response?.data?.resDecrypt?.response);
              setTrip_Terminate(true);

              // navigate("/kiosk-app/request-trip-info", {
              //   state: { tripDetails: response?.data?.resDecrypt?.response },
              // });
            }
          } else {
            if (user.p_phone) {
              sendSMSHandler(response?.data?.resDecrypt?.response.otp);
            }
            navigate("/trip-info", {
              state: { tripDetails: response?.data?.resDecrypt?.response },
            });
          }
        }
      } else {
        setErrors(formErrors);
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };


  const sendSMSDriverHandler = async (otp, tripDetails) => {

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const R = 6379; // Earth's radius in kilometers
      const toRadians = (deg) => (deg * Math.PI) / 180;

      const dLat = toRadians(lat2 - lat1);
      const dLon = toRadians(lon2 - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c; // Distance in kilometers
    };

    const calculateDuration = (distance, averageSpeed = 40) => {
      return distance / averageSpeed; // Duration in hours
    };


    const calculateEstimatedArrivalTime = (orLat, orLng, deLat, deLng) => {
      const distance = calculateDistance(orLat, orLng, deLat, deLng); // In kilometers
      const duration = calculateDuration(distance); // Duration in hours

      const durationInMinutes = Math.round(duration * 60); // Convert to minutes
      return `${durationInMinutes} mins`;
    };

    // Example usage
    const orLat = tripDetails?.Driver?.d_lat;
    const orLng = tripDetails?.Driver?.d_lng;
    const deLat = tripDetails?.trip_scheduled_pick_lat;
    const deLng = tripDetails?.trip_scheduled_pick_lng;

    const estimatedArrivalTime = calculateEstimatedArrivalTime(orLat, orLng, deLat, deLng);

    var e = new Date(tripDetails.trip_date);
    var utc_offset_gmt = e.getTimezoneOffset();
    e.setMinutes(e.getMinutes() - utc_offset_gmt);
    const date = moment(e).format("YYYY-MM-DD");
    const time = moment(e).format("hh:mm a");
    const formattedDateTime = `${date} ${time}`;

    const params = {
      lang: "en",
      msg: 1232,
      otp: otp,
      ph: phone,
      c_code: countryCode,
      date: formattedDateTime,
      driver_name: tripDetails?.Driver?.d_name,
      driver_contact: tripDetails?.Driver?.d_phone
        ? tripDetails?.Driver?.d_phone
        : "Not available",
      vehicle_type: tripDetails.cat_name,
      vehicle_name: tripDetails?.Driver?.car_name,
      vehicle_model: tripDetails?.Driver?.car_model,
      vehicle_registration_number: tripDetails?.Driver?.car_reg_no
        ? tripDetails?.Driver?.car_reg_no
        : "Not available",
      from_address: tripDetails.trip_from_loc,
      to_Address: tripDetails.trip_to_loc
        ? tripDetails.trip_to_loc
        : "Not available",
      estimated_fare: `OMR ${tripDetails.trip_pay_amount?.toFixed(2) || "0.00"}`, // Default value for trip_pay_amount
      estimated_time_of_arrival: estimatedArrivalTime || "Not available",
      tracking_url: `${process.env.REACT_APP_TRACKING_URL}?trip=${tripDetails.trip_id}`
    };

    try {
      const response = await ApiService.post(`${KioskSMS}?`, params);

      if (response.status === 200) {
        // toast.success(
        //   "Please check if your WhatsApp details have been sent successfully."
        // );
      }
    } catch (error) {
      console.error("Try again", error);
      toast.error("Failed to send message. Please try again.");
    }

    const data = {
      lang: "ar",
      msg: 651965,
      otp: otp,
      ph: phone,
      c_code: countryCode,
      date: formattedDateTime,
      driver_name: tripDetails?.Driver?.d_name,
      driver_contact: tripDetails?.Driver?.d_phone ? tripDetails?.Driver?.d_phone : "Not available",
      vehicle_type: tripDetails.cat_name,
      vehicle_name: tripDetails?.Driver?.car_name,
      vehicle_model: tripDetails?.Driver?.car_model,
      vehicle_registration_number: tripDetails?.Driver?.car_reg_no ? tripDetails?.Driver?.car_reg_no : "Not available",
      from_address: tripDetails.trip_from_loc,
      to_Address: tripDetails.trip_to_loc ? tripDetails.trip_to_loc : "Not available",
      estimated_fare: `OMR ${tripDetails.trip_pay_amount?.toFixed(2) || "0.00"}`, // Default value for trip_pay_amount
      estimated_time_of_arrival: estimatedArrivalTime || "Not available",
      tracking_url: `${process.env.REACT_APP_TRACKING_URL}?trip=${tripDetails.trip_id}`

    };

    try {
      const response = await ApiService.post(`${KioskSMS}?`, data);

      if (response.status === 200) {
        toast.success(
          "Please check your WhatsApp details have been sent successfully."
        );
      }
    } catch (error) {
      console.error("Try again", error);
      toast.error("Failed to send message. Please try again.");
    }
  };

  const sendSMSHandler = async (otp) => {
    const params = {
      lang: "en",
      msg: 1232,
      otp: otp,
      ph: ph_number,
      c_code: country_code,
    };

    try {
      const response = await ApiService.post(`${KioskSMS}?`, params);

      if (response.status === 200) {
        // toast.success(
        //   "Please check if your WhatsApp details have been sent successfully."
        // );
      }
    } catch (error) {
      console.error("Try again", error);
      toast.error("Failed to send message. Please try again.");
    }

    const data = {
      lang: "ar",
      msg: 1232,
      otp: otp,
      ph: ph_number,
      c_code: country_code,
    };

    try {
      const response = await ApiService.post(`${KioskSMS}?`, data);

      if (response.status === 200) {
        toast.success(
          "Please check your WhatsApp details have been sent successfully."
        );
      }
    } catch (error) {
      console.error("Try again", error);
      toast.error("Failed to send message. Please try again.");
    }
  };

  const trip_Id = sessionStorage.getItem("trip_id");

  useEffect(() => {
    const fetchTrip = async () => {
      if (driverId > 0) {
        // Stop polling if driverId is greater than 0
        return;
      }

      let params = {
        trip_id: trip_Id,
      };

      const config = {
        headers: {
          "Disable-Loader": true, // Customize this flag to disable the loader for this specific call
        },
      };
      const ride_type = sessionStorage.getItem("ride_type");

      if (trip_Id && ride_type?.toLowerCase() == "req") {
        try {
          const response = await ApiService.post(
            `${GET_TRIPS}?api_key=${apiKey}`,
            params,
            config
          );

          if (response.status === 200) {
            const fetchedDriverId =
              response.data?.resDecrypt?.response[0]?.driver_id;
            if (
              response.data?.resDecrypt?.response[0]?.trip_status?.toLowerCase() !=
              "request"
            ) {
              setTrip_Terminate(false); // Example of manual update
              localStorage.removeItem("trip_terminate");
              sessionStorage.removeItem("trip_id");
              handleResetAddress();
            }
            if (
              fetchedDriverId > 0 &&
              response.data?.resDecrypt?.response[0]?.trip_status?.toLowerCase() !=
              "p_cancel_pickup"
            ) {
              //sendOTP

              if (phone) {
                sendSMSDriverHandler(
                  response?.data?.resDecrypt?.response[0].otp,
                  response?.data?.resDecrypt?.response[0]
                );
              }
              setTrip_Terminate(false); // Example of manual update
              localStorage.removeItem("trip_terminate");
              sessionStorage.removeItem("trip_id");
              // Set driverId in state and navigate immediately
              navigate("/request-trip-info", {
                state: {
                  tripDetails: response?.data?.resDecrypt?.response[0],
                },
              });


              setDriverId(fetchedDriverId);
            }
          }
        } catch (error) {
          console.error("Error fetching trip data:", error);
        }
      }
    };

    // Initial call
    fetchTrip();

    // Poll every 10 seconds
    const intervalId = setInterval(fetchTrip, 10000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [apiKey, trip_Id, driverId, navigate]);

  const getDistanceMatrixAndEstimatePriceList = async () => {
    try {
      setShowCategory(false);
      setEstimatedPriceList([]);
      setEstimatedPrice(0);
      setSelectedVehicle(null);
      setDistancematrix((prev) => ({
        ...prev,
        distance: 0,
        duration: 0,
        calculatedDistance: 0,
        calculatedDirection: 0,
      }));
      if (
        fromLocationAddress.address !== "" &&
        fromLocationAddress.selected &&
        toLocationAddress.address !== "" &&
        toLocationAddress.selected
      ) {
        const response = await ApiService.post(
          `${process.env.REACT_APP_DISTANCE_MATRIX_URL}?orlat=${fromLocationAddress.lat}&orlng=${fromLocationAddress.lng}&delat=${toLocationAddress.lat}&delng=${toLocationAddress.lng}`
        );
        if (response.status === 200) {
          let result = response.data;
          setRoute(result?.routes[0]?.overview_polyline?.points);
          let distance = result?.routes[0]?.legs[0]?.distance?.text;
          let duration = result?.routes[0]?.legs[0]?.duration?.text;
          let calculatedDistance =
            result?.routes[0]?.legs[0]?.distance?.value / 1000;
          let calculatedDirection =
            result?.routes[0]?.legs[0]?.duration?.value / 60;

          setDistancematrix((prev) => ({
            ...prev,
            distance: distance,
            duration: duration,
            calculatedDistance: calculatedDistance,
            calculatedDirection: calculatedDirection,
          }));
          // estimated price list
          let data = {
            trip_scheduled_pick_lat: fromLocationAddress.lat,
            trip_scheduled_pick_lng: fromLocationAddress.lng,
            trip_scheduled_drop_lat: toLocationAddress.lat,
            trip_scheduled_drop_lng: toLocationAddress.lng,
            trip_distance: calculatedDistance,
            trip_hrs: calculatedDirection,
            trip_from_loc: fromLocationAddress.address,
            trip_to_loc: toLocationAddress.address,
            trip_date: dayjs().tz("GMT").format("YYYY-MM-DD HH:mm:ss"),
            api_key: apiKey,
            city_id: cityId, //process.env.REACT_APP_CITY_ID,
            kiosk_id: userId,
            seats: 1,
            is_share: 1,
            polyline: "0",
          };

          const parentId = sessionStorage.getItem("parent_id");
          if (parentId) {
            data.kiosk_owner_id = parentId;
          }

          const estimatedPriceResponse = await ApiService.post(
            GET_KIOSK_ESTIMATE_TRIP_FARE,
            data
          );

          if (estimatedPriceResponse?.status === 200) {
            setEstimatedPriceList(
              estimatedPriceResponse.data.resDecrypt.response
            );
          }
        }
      } else {
        // estimated price list
        let data = {
          trip_scheduled_pick_lat: fromLocationAddress.lat,
          trip_scheduled_pick_lng: fromLocationAddress.lng,
          trip_scheduled_drop_lat: toLocationAddress.lat,
          trip_scheduled_drop_lng: toLocationAddress.lng,
          trip_distance: distanceMatrix.calculatedDistance,
          trip_hrs: distanceMatrix.calculatedDirection,
          trip_from_loc: fromLocationAddress.address,
          trip_to_loc: toLocationAddress.address,
          trip_date: dayjs().tz("GMT").format("YYYY-MM-DD HH:mm:ss"),
          api_key: apiKey,
          city_id: cityId, //process.env.REACT_APP_CITY_ID,
          kiosk_id: userId,
          seats: 1,
          is_share: 1,
          polyline: "0",
        };

        const parentId = sessionStorage.getItem("parent_id");
        if (parentId) {
          data.kiosk_owner_id = parentId;
        }

        const estimatedPriceResponse = await ApiService.post(
          GET_KIOSK_ESTIMATE_TRIP_FARE,
          data
        );

        if (estimatedPriceResponse?.status === 200) {
          setEst(estimatedPriceResponse.data.resDecrypt.response);

          setEstimatedPriceList(
            estimatedPriceResponse.data.resDecrypt.response
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setShowCategory(true);
    }
  };

  const getDistanceMatrixAndEstimatePriceListbk = async () => {
    try {
      setShowCategory(false);
      const response = await ApiService.post(
        `${process.env.REACT_APP_DISTANCE_MATRIX_URL}?orlat=${fromLocationAddress.lat}&orlng=${fromLocationAddress.lng}&delat=${toLocationAddress.lat}&delng=${toLocationAddress.lng}`
      );
      if (response.status === 200) {
        let result = response.data;
        let distance = result?.routes[0]?.legs[0]?.distance?.text;
        let duration = result?.routes[0]?.legs[0]?.duration?.text;
        let calculatedDistance =
          result?.routes[0]?.legs[0]?.distance?.value / 1000;
        let calculatedDirection =
          result?.routes[0]?.legs[0]?.duration?.value / 60;

        setDistancematrix((prev) => ({
          ...prev,
          distance: distance,
          duration: duration,
          calculatedDistance: calculatedDistance,
          calculatedDirection: calculatedDirection,
        }));

        // estimated price list
        let data = {
          trip_scheduled_pick_lat: fromLocationAddress.lat,
          trip_scheduled_pick_lng: fromLocationAddress.lng,
          trip_scheduled_drop_lat: toLocationAddress.lat,
          trip_scheduled_drop_lng: toLocationAddress.lng,
          trip_distance: calculatedDistance,
          trip_hrs: calculatedDirection,
          trip_from_loc: fromLocationAddress.address,
          trip_to_loc: toLocationAddress.address,
          trip_date: dayjs().tz("GMT").format("YYYY-MM-DD HH:mm:ss"),
          api_key: apiKey,
          city_id: cityId, //process.env.REACT_APP_CITY_ID,
          kiosk_id: userId,
          seats: 1,
          is_share: 1,
          polyline: "0",
        };

        const parentId = sessionStorage.getItem("parent_id");
        if (parentId) {
          data.kiosk_owner_id = parentId;
        }

        const estimatedPriceResponse = await ApiService.post(
          GET_KIOSK_ESTIMATE_TRIP_FARE,
          data
        );

        if (estimatedPriceResponse?.status === 200) {
          setEst(estimatedPriceResponse.data.resDecrypt.response);

          setEstimatedPriceList(
            estimatedPriceResponse.data.resDecrypt.response
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setShowCategory(true);
    }
  };

  const getEstimatePriceList = async () => {
    try {
      let data = {
        trip_scheduled_pick_lat: fromLocationAddress.lat,
        trip_scheduled_pick_lng: fromLocationAddress.lng,
        trip_scheduled_drop_lat: toLocationAddress.lat,
        trip_scheduled_drop_lng: toLocationAddress.lng,
        trip_distance: distanceMatrix.calculatedDistance,
        trip_hrs: distanceMatrix.calculatedDirection,
        trip_from_loc: fromLocationAddress.address,
        trip_to_loc: toLocationAddress.address,
        trip_date: "2024-04-17 11:48:56",
        api_key: apiKey,
        city_id: cityId, //process.env.REACT_APP_CITY_ID,
        user_id: userId,
        seats: 1,
        is_share: 1,
        polyline: "0",
      };

      const response = await ApiService.post(GET_ESTIMATE_TRIP_FARE, data);

      if (response?.status === 200) {
        setEstimatedPriceList(response.data.resDecrypt.response);
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  const getEstimatePriceListbk = async () => {
    try {
      let data = {
        trip_scheduled_pick_lat: fromLocationAddress.lat,
        trip_scheduled_pick_lng: fromLocationAddress.lng,
        trip_scheduled_drop_lat: toLocationAddress.lat,
        trip_scheduled_drop_lng: toLocationAddress.lng,
        trip_distance: distanceMatrix.calculatedDistance,
        trip_hrs: distanceMatrix.calculatedDirection,
        trip_from_loc: fromLocationAddress.address,
        trip_to_loc: toLocationAddress.address,
        trip_date: "2024-04-17 11:48:56",
        api_key: apiKey,
        city_id: cityId, //process.env.REACT_APP_CITY_ID,
        user_id: userId,
        seats: 1,
        is_share: 1,
        polyline: "0",
      };

      const response = await ApiService.post(GET_ESTIMATE_TRIP_FARE, data);

      if (response?.status === 200) {
        setEstimatedPriceList(response.data.resDecrypt.response);
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  useEffect(() => {
    // update pricelist, if from and to locations are selected
    //if (fromLocationAddress.address !== "" && fromLocationAddress.selected && toLocationAddress.address !== "" && toLocationAddress.selected && estimatedPriceList.length === 0)
    if (fromLocationAddress.address !== "" && fromLocationAddress.selected) {
      (async () => {
        await getDistanceMatrixAndEstimatePriceList();
        // await getEstimatePriceList();
      })();
    } else {
      setEstimatedPriceList([]);
      setEstimatedPrice(0);
      setSelectedVehicle(null);
    }
  }, [fromLocationAddress.selected, toLocationAddress.selected]);

  // fetch list of categories
  const getCategories = async () => {
    try {
      let data = {
        city_id: cityId, //process.env.REACT_APP_CITY_ID,
      };
      const response = await ApiService.post(`${GET_CATEGORIES_V1}`, data);
      if (response.status === 200) {
        setCategories(response.data.resDecrypt.response);
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const logoUrl = sessionStorage.getItem("logo");

  const openDetailHandler = () => {
    setShowDetails(true);
  };

  const closeDetailHandler = () => {
    setShowDetails(false);
  };

  const handleClearName = () => {
    setName("");
  };

  const [userDetailPopup, setUserDetailPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const handleClick = () => {
    setIsDisabled(true); // Disable the button
    setTimeout(() => {
      setIsDisabled(false); // Re-enable the button after 5 seconds
    }, 5000);
  };
  const userDetail = (e) => {
    e?.preventDefault(e);
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      let username = sessionStorage.getItem("phone");
      if (username === "" || username === null) {
        navigate("/login");
        return;
      }
    }
    if (formErrors.selectedVehicle === "*Please select a vehicle") {
      return;
    }

    handleClick()
    setUserDetailPopup(!userDetailPopup);
  };

  const continueRideBooking = () => {
    setUserDetailPopup(false);
    handleBookNow();
  };

  return (
    <>
      {userDetailPopup && (
        <SendMessageDuringTrip
          closeDetailHandler={userDetail}
          continueRideBooking={continueRideBooking}
          setUserDetail={setUserDetail}
          onPhoneChangeData={onPhoneChange}
        />
      )}
      {showDetails && (
        <Detailpage
          estimatedPriceList={detailCat}
          distanceMatrix={distanceMatrix}
          currency={currency}
          closeDetailHandler={closeDetailHandler}
          categories={categories}
          selectedVehicle={selectedVehicle.category_id}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
          marginBottom: "20px",
        }}
      >
        <span
          style={{
            maxWidth: "250px",
            height: "120px", // corrected 'Height' to 'height'
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
            overflow: "hidden", // ensures the image doesn't overflow
          }}
        >
          <img
            src={logoUrl}
            alt="logo"
            className="logo-cl"
            style={{
              objectFit: "contain",
              width: "80%", // ensures the image covers the full width of the container
              height: "80%", // ensures the image fills the container height
            }}
          />
        </span>

        <span
          style={{
            maxWidth: "250px",
            height: "120px", // corrected 'Height' to 'height'
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
            overflow: "hidden", // ensures the image doesn't overflow
          }}
        >
          <img
            src="images/muscat.png"
            alt="logo"
            className="logo-cl"
            style={{
              objectFit: "contain",
              width: "80%", // ensures the image covers the full width of the container
              height: "80%", // ensures the image fills the container height
            }}
          />
        </span>
      </div>

      <form onSubmit={userDetail} className="kiosk-booking-form">
        {/* From location selection */}
        <div id="" className="col-md-auto col-sm-auto">
          <strong className="location-label">
            <span>From / من</span>
            {errors.fromLocation && (
              <label className="error-message">{errors.fromLocation}</label>
            )}
          </strong>
          <div
            style={{ zIndex: "99" }}
            className="field-holder field-holderb border padding-input"
          >
            <PlacesAutocomplete
              value={fromLocationAddress.address}
              onChange={onChangeFromLocationAddress}
              onSelect={handleFromLocationSelect}
              ref={fromAutocompleteRef}
              searchOptions={{
                componentRestrictions: { country: "OM" }, // Restrict to Oman
                radius: 314000,
                location: new window.google.maps.LatLng(
                  UserLocationAddress.lat,
                  UserLocationAddress.lng
                ), // User's location
                rankBy: window.google.maps.places.RankBy.DISTANCE, // Rank results by proximity
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    style={{ zIndex: "9999" }}
                    {...getInputProps({
                      placeholder: "Pickup Location",
                      className: "location-search-input",
                    })}
                    readOnly={fromLocationAddress.selected}
                  />
                  {fromLocationAddress.address && (
                    <span
                      className="fas fa-times-circle"
                      style={{
                        marginRight: "0px",
                        fontSize: "17px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearFromLocation}
                    ></span>
                  )}
                  {/* <span className="fas fa-map-marker-alt"></span> */}
                  <div className="autocomplete-dropdown-container">
                    {loading && <div className="">loading...</div>}
                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          style={{ zIndex: "9999" }}
                          className="input-suggestion"
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          <i className="material-icons">location_on</i>{" "}
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

        {/* <div id="FROMLOCATIONL" className="col-md-auto col-sm-auto">
          <strong className="location-label">
            <span>From</span>
            {errors.fromLocation && (
              <label className="error-message">{errors.fromLocation}</label>
            )}
          </strong>
          <div
            style={{ zIndex: "99" }}
            className="field-holder field-holderb border padding-input"
          >
		<p className="location-search-input">{username}</p>
          </div>
        </div> */}

        {/* To Location selection */}
        <div className="col-md-auto col-sm-auto">
          <strong className="location-label">
            <span>
              To / إلى
              <span style={{ fontSize: 16 }}>&nbsp;(Optional / اختياري)</span>
            </span>
            {errors.toLocation && (
              <label className="error-message">{errors.toLocation}</label>
            )}
          </strong>
          <div className="field-holder field-holderb border">
            <PlacesAutocomplete
              value={toLocationAddress.address}
              onChange={onChangeToLocationAddress}
              onSelect={handleToLocationSelect}
              ref={toAutocompleteRef}
              searchOptions={{
                componentRestrictions: { country: "OM" }, // Restrict to Oman
                radius: 314000,
                location: new window.google.maps.LatLng(
                  fromLocationAddress.lat,
                  fromLocationAddress.lng
                ), // User's location
                rankBy: window.google.maps.places.RankBy.DISTANCE, // Rank results by proximity
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: "Drop Location",
                      className: "location-search-input",
                    })}
                    readOnly={toLocationAddress.selected}
                  />
                  {toLocationAddress.address && (
                    <span
                      className="fas fa-times-circle"
                      style={{
                        marginRight: "0px",
                        fontSize: "17px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearToLocation}
                    ></span>
                  )}
                  {/* <span className="fas fa-map-marker-alt"></span> */}
                  <div className="autocomplete-dropdown-container">
                    {loading && <div className="">loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={index}
                          className="input-suggestion"
                          {...getSuggestionItemProps(suggestion)}
                          style={style}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor = "#ddd")
                          }
                          onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            suggestion.active ? "#ddd" : "#ffffff")
                          }
                        >
                          <i className="material-icons">location_on</i>{" "}
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

        {/*         <div className="col-md-auto col-sm-auto">
          <strong className="location-label">
            <span>
              Name / الاسم<span style={{ fontSize: 16 }}>&nbsp;(Optional)</span>
            </span>
          </strong>
          <div className="field-holder field-holderb border">
            <input
              type="text"
              placeholder="Please Enter Your Name"
              class="location-search-input"
              value={name.address}
              onChange={(e) => setName(e.target.value)}
            />{" "}
          </div>
        </div>
        <div className="col-md-auto col-sm-auto">
          <strong className="location-label">
            <span>
              Phone / الهاتف{" "}
              <span style={{ fontSize: 16 }}>&nbsp;(Optional)</span>
            </span>
          </strong>
          <div className="field-holder ph field-holderb border">
            <PhoneInput
              country={"om"} // Sets Oman as the default country
              value={phone}
              onChange={onPhoneChange}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: false,
              }}
              inputClass="location-search-input"
            />
          </div>
        </div> */}

        {/* Cars category selection */}
        {showCategory && fromLocationAddress.address && (
          <div className="col-md-auto col-sm-auto">
            <strong className="location-label">
              <span>Category / الفئة</span>

              {errors.category && (
                <label id="u_email-error" className="error-message">
                  {errors.category}
                </label>
              )}
            </strong>
            <span
              style={{
                fontSize: "12px",
                fontWeight: 700,
                top: "-6px",
                position: "relative",
              }}
            >
              Select your vehicle type / اختر نوع سيارتك{" "}
            </span>

            {Array.isArray(categories) ? (
              categories.filter((category) => category.is_kiosk === 1).length >
                0 ? (
                <div className="flex-wrapper category-width">
                  {categories
                    .filter((category) => category.is_kiosk === 1)
                    .sort((a, b) => a.sort_order - b.sort_order)
                    .slice(0, 10)
                    .map((category, index) => (
                      <div
                        key={index}
                        className={`catborder-reserve ${selectedVehicle &&
                          selectedVehicle.category_id === category.category_id
                          ? "selectvehicle"
                          : ""
                          }`}
                        onClick={handleSelectedVehicle(category)} // Correctly handle the click event
                      >
                        <div className="fleet-name">{category.cat_name}</div>
                        <img
                          src={category.cat_icon_path}
                          alt="Oops"
                          className="catimg-reserve"
                        />
                        <span
                          onClick={openDetailHandler}
                          style={{
                            position: "relative",
                            top: "-24px",
                            float: "right",
                          }}
                        >
                          <svg
                            viewBox="0 0 48 48"
                            xmlns="http://www.w3.org/2000/svg"
                            height={20}
                          >
                            <g fill="#b88a32" class="fill-241f20">
                              <path d="M24 .125C10.814.125.125 10.814.125 24S10.814 47.875 24 47.875 47.875 37.186 47.875 24 37.186.125 24 .125zm0 43.771C13.012 43.896 4.104 34.988 4.104 24S13.012 4.104 24 4.104 43.896 13.012 43.896 24 34.988 43.896 24 43.896z"></path>
                              <path d="M25.22 16.234c.572 0 1.097-.202 1.57-.606.469-.404.757-.894.86-1.466.098-.576-.024-1.062-.38-1.466-.354-.408-.818-.61-1.392-.61-.576 0-1.1.202-1.569.61-.474.403-.762.89-.86 1.466-.071.573.066 1.062.403 1.466.338.404.796.606 1.368.606zM29.622 30.4c-.053.075-.104.14-.156.216-.312.396-1.475 1.795-2.984 2.632a.06.06 0 0 0-.024.014c-.132.07-.267.123-.401.185a3.537 3.537 0 0 1-.891.188c-.339-.044-.523-.281-.523-.73 0-.371.109-1.104.329-2.205l.253-1.111 1.035-4.968c.168-.846.258-1.316.278-1.409l.226-1.162c.133-.719.201-1.194.201-1.426 0-.444-.11-.781-.287-1.055a.613.613 0 0 0-.066-.122c-.026-.032-.054-.06-.082-.089-.031-.034-.046-.063-.088-.098-.441-.36-.853-.464-1.137-.487l.005-.008s-1.867-.141-4.633 1.682c-.04.026-.064.045-.101.07-.749.474-1.348.949-1.763 1.332a5.178 5.178 0 0 0-.745.762l-.008.011.001-.001c-.236.311-.36.593-.36.843 0 .197.16.394.476.591 0 0 1.188-1.534 3.185-2.532.15-.065.534-.224.872-.312.157-.036.455-.064.645.093.136.142.226.339.226.646 0 .279-.042.618-.127 1.023l-.202.957-.251 1.196-.986 4.728c-.504 2.442-.757 3.903-.757 4.379 0 1.123.615 1.685 1.843 1.685.557 0 1.129-.101 1.716-.281.004 0 .006.002.01.004l.116-.043c.097-.031.195-.068.292-.105 2.771-1.031 4.595-3.108 5.146-3.804a3.546 3.546 0 0 0 .181-.239h-.002c.14-.206.217-.377.217-.505-.002-.179-.233-.358-.679-.545z"></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    ))}{" "}
                </div>
              ) : (
                <div style={{ fontSize: 18, fontWeight: 700, padding: 20 }}>
                  No service available / لا توجد خدمة متاحة
                </div>
              )
            ) : null}
          </div>
        )}

        <div className="col-md-auto col-sm-auto">
          <p className="tnc-container" style={{ paddingLeft: 25 }}>
            أفهم وأوافق على{" "}
            <a target="_blank" href="/term-condition" style={{ color: "red" }}>
              شروط الخدمة{" "}
            </a>{" "}
            والإلغاء
          </p>

          <div className="tnc-container" style={{ marginTop: 0 }}>
            {" "}
            <input
              name="book_terms"
              id="book_terms"
              checked={termsCheck}
              type="checkbox"
              onChange={(e) => setTermsCheck(!termsCheck)}
            />
            <p>
              I understand and agree with the{" "}
              <a target="_blank" href="/term-condition">
                Terms of Service
              </a>{" "}
              and Cancellation.{" "}
            </p>
          </div>
          {errors.terms && (
            <label className="error-message">{errors.terms}</label>
          )}

          {estimatedPrice !== 0 && (
            <div className="estimated-trip-fare">
              <strong>
                {" "}
                Estimated Fare / الأجرة المقدرة: {currency}{" "}
                {estimatedPrice.toFixed(2)}
              </strong>
            </div>
          )}
        </div>

        <div className="col-md-auto col-sm-auto form-group">
          <button
            className="book-btn"
            style={{ width: "100%", marginTop: "7px" }}
            type="submit"
            disabled={isDisabled} // Disable the button when isDisabled is true

          >
            Book Now / احجز الآن{" "}
            <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
          </button>
        </div>
      </form>
      {trip_terminate && (
        <>
          {" "}
          <React.Fragment>
            <div className="backdrop" />

            <div className="center-screen" style={{ position: "absolute" }}>
              <div className="offercount">
                <div
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginBottom: 20,
                  }}
                >
                  {" "}
                  Waiting for driver response / في انتظار رد السائق
                </div>
                <div style={{ display: "grid" }}>
                  <div style={{ display: "grid", position: "relative" }}>
                    <div className="dot red-dot" />
                    <div style={{ marginLeft: "20px" }}>
                      Pickup Location / موقع الالتقاط:
                      <div className="loc" style={{ display: "grid" }}>
                        {tripDetails.trip_from_loc}{" "}
                      </div>
                    </div>
                    {tripDetails.trip_to_loc && (
                      <>
                        <div className="vertical-line"></div>
                        <div className="dot green-dot" />
                        <div style={{ marginLeft: "20px", marginTop: 15 }}>
                          Drop Location / موقع الإنزال:
                          <div className="loc">{tripDetails.trip_to_loc}</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="col-md-auto col-sm-auto form-group"
                style={{ marginTop: 30 }}
              >
                <button
                  className="book-btn"
                  style={{
                    width: "100%",
                    height: "60%",
                    marginTop: "7px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleCancelRide}
                >
                  Cancel{" "}
                </button>
              </div>
            </div>
          </React.Fragment>
        </>
      )}
    </>
  );
};

export default KioskHotelBookingForm;
