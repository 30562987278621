import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutModal from "../components/LogoutModal";
import { toast } from "react-toastify";
import ApiService from "../../../utils/Api";
import { GET_TRIPS, USER_LOGIN } from "../../../utils/Endpoint";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import { SendMessage } from "../../../components/popup/sendOtp";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { SendMessageWithDriverDetail } from "../../../components/popup/sentOtpWithDriverDetail";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault();

const ReceiptBody = ({ tripDetails }) => {
  const logoUrl = sessionStorage.getItem("logo");
  return (
    <div
      className="trip-receipt"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="form-container col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
        <div className="trip-details-container2">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                maxWidth: "250px",
                // height: "120px",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
                overflow: "hidden", // ensures the image doesn't overflow
              }}
            >
              <img
                src={logoUrl}
                alt="logo"
                style={{
                  objectFit: "contain",
                  width: "50%", // ensures the image covers the full width of the container
                  height: "80%", // ensures the image fills the container height
                }}
              />
            </span>
          </div>
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            <h4
              style={{
                textAlign: "center",
                marginTop: 10,
                marginBottom: 4,
              }}
            >
              OTP / كلمة المرور لمرة واحدة
            </h4>
            <h1
              style={{
                textAlign: "center",
                letterSpacing: 3,
                fontSize: "60px",
                marginTop: 2,
                marginBottom: 5,
              }}
            >
              {tripDetails.otp}
            </h1>
          </div>
          <div
            className="qr-code-container"
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "15px",
              marginBottom: "0px",
            }}
          >
            <span
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <QRCode value={tripDetails.otp} width={60} height={60} />{" "}
              <div
                style={{
                  padding: 10,
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>
                  Vehicle Type
                  <p style={{ textAlign: "center" }}>نوع السيارة</p>
                </p>
                <p style={{ fontWeight: 500, fontSize: 20 }}>
                  {tripDetails.cat_name}
                </p>
              </div>
            </span>
            <p style={{ paddingTop: 10, fontSize: 10, margin: 0 }}>
              Please share the OTP or scan the QR Code with the Driver to
              start the trip.
              <p style={{ textAlign: "right" }}>
                {" "}
                يرجى مشاركة كلمة المرور لمرة واحدة أو مسح رمز الاستجابة السريعة مع السائق
                لبدء الرحلة
              </p>
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h6>Driver Details / تفاصيل السائق</h6>
          </div>
          <table className="table table-bordered" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td scope="row" style={{ fontSize: 12, padding: 2 }}>
                  Date / التاريخ{" "}
                </td>
                <th style={{ fontSize: 12, padding: 2 }}>
                  {dayjs
                    .utc(tripDetails.trip_date)
                    .tz("Asia/Muscat")
                    .format("YYYY-MM-DD HH:mm")}
                </th>
              </tr>
              <tr>
                <td
                  scope="row"
                  style={{
                    // width: "50%",
                    fontSize: 15,
                    padding: 2,
                  }}
                >
                  Driver Name / اسم السائق
                </td>
                <th style={{ width: "50%", fontSize: 12, padding: 2 }}>
                  {tripDetails.Driver?.d_name}
                </th>
              </tr>
              <tr>
                <td
                  scope="row"
                  style={{
                    // width: "50%",
                    fontSize: 15,
                    padding: 2,
                  }}
                >
                  Driver Contact / رقم الاتصال بالسائق
                </td>
                <th style={{ width: "50%", fontSize: 12, padding: 2 }}>
                  {tripDetails.Driver?.d_phone}
                </th>
              </tr>
              <tr>
                <td
                  scope="row"
                  style={{
                    // width: "50%",
                    fontSize: 15,
                    padding: 2,
                  }}
                >
                  Vehicle Name / اسم المركبة
                </td>
                <th style={{ width: "50%", fontSize: 12, padding: 2 }}>
                  {tripDetails.Driver?.car_name}
                </th>
              </tr>
              <tr>
                <td
                  scope="row"
                  style={{
                    // width: "50%",
                    fontSize: 15,
                    padding: 2,
                  }}
                >
                  Vehicle Model / طراز السيارة
                </td>
                <th style={{ width: "50%", fontSize: 12, padding: 2 }}>
                  {tripDetails.Driver?.car_model}
                </th>
              </tr>
              <tr>
                <td
                  scope="row"
                  style={{
                    // width: "50%",
                    fontSize: 15,
                    padding: 2,
                  }}
                >
                  Vehicle Reg No / رقم مسجل المركبات
                </td>
                <th style={{ width: "50%", fontSize: 12, padding: 2 }}>
                  {tripDetails.Driver?.car_reg_no}
                </th>
              </tr>
            </tbody>
          </table>

          <div style={{display:"grid"}}>
            {" "}
            <div>
              <div className=" col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "9px",
                    marginLeft: "-13px",
                    lineHeight: "10px",
                  }}
                >
                  {" "}
                  Happy Journey… Thank you for using our service!
                  <br />
                </p>
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "9px",
                    marginLeft: "-13px",
                    lineHeight: "10px",
                  }}
                >
                  *Kindly note that the OTP will expire in 120 minutes
                </p>
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "9px",
                    marginLeft: "-13px",
                    lineHeight: "10px",
                  }}
                >
                  **You are not obliged for payment if no trip is availed
                </p>
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "9px",
                    marginLeft: "-13px",
                    lineHeight: "10px",
                  }}
                >
                  ***Fare may vary if Vehicle Type is changed
                </p>
              </div>
              <div className=" col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <p
                  style={{
                    textAlign: "right",
                    fontSize: "9px",
                    marginLeft: "-13px",
                    lineHeight: "10px"
                  }}
                >
                  رحلة سعيدة… شكرًا لاستخدامكم خدمتنا
                </p>
                <p
                  style={{
                    textAlign: "right",
                    fontSize: "9px",
                    marginLeft: "-13px",
                    lineHeight: "10px"
                  }}
                >
                  يرجى ملاحظة أن كلمة المرور لمرة واحدة سينتهي بعد 120 دقيقة
                </p>
                <p
                  style={{
                    textAlign: "right",
                    fontSize: "9px",
                    marginLeft: "-13px",
                    lineHeight: "10px"
                  }}
                >
                  أنت غير ملزم بالدفع إذا لم تتم الاستفادة من الرحلة
                </p>{" "}
                <p
                  style={{
                    textAlign: "right",
                    fontSize: "9px",
                    marginLeft: "-13px",
                    lineHeight: "10px",
                    paddingBottom: 15,
                  }}
                >
                  قد تتغير الأجرة إذا تم تغيير نوع المركبة
                </p>
              </div>
            </div>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Powered by{" "}
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    maxWidth: "100px",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
                    overflow: "hidden", // ensures the image doesn't overflow
                  }}
                >
                  <img
                    src="images/muscat.png"
                    alt="logo"
                    style={{
                      objectFit: "contain",
                      width: "50%", // ensures the image covers the full width of the container
                      height: "50%", // ensures the image fills the container height
                    }}
                  />
                </span>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const KioskTripReqInfo = () => {
  const location = useLocation();
  const tripDetails = location.state.tripDetails;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId] = useState(sessionStorage.getItem("userid") || "");
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const [isPrintToogle, setIsPrintTooggle] = useState(false);
  const [driverId, setDriverId] = useState(null);
  //   const [tripDetails, setTripDetail] = useState({});
  const [showDetails, setShowDetails] = useState(false);

  const trip_Id = sessionStorage.getItem("trip_id");
  const cityId = sessionStorage.getItem("city_id");
  const currency = sessionStorage.getItem("currency");
  const apiKey = sessionStorage.getItem("key");

  const isprint = sessionStorage.getItem("is_print_available");

  useEffect(() => {
    setIsPrintTooggle(isprint == "1" ? true : false);
  });

  //   useEffect(() => {
  //     const fetchTrip = async () => {
  //       //   if (driverId > 0) {
  //       //     // Stop polling if driverId is greater than 0
  //       //     return;
  //       //   }

  //       let params = {
  //         trip_id: trip_Id,
  //       };

  //       const config = {
  //         headers: {
  //           "Disable-Loader": true, // Customize this flag to disable the loader for this specific call
  //         },
  //       };

  //       if (trip_Id) {
  //         try {
  //           const response = await ApiService.post(
  //             `${GET_TRIPS}?api_key=${apiKey}`,
  //             params,
  //             config
  //           );

  //           if (response.status === 200) {
  //             const fetchedDriverId = response.data.resDecrypt.response.driver_id;

  //             setDriverId(fetchedDriverId); // Set driverId in state to stop further polling
  //             console.log(response);
  //             setTripDetail(response?.data?.resDecrypt?.response[0]);
  //           }
  //         } catch (error) {
  //           console.error("Error fetching trip data:", error);
  //         }
  //       }
  //     };

  //     // Initial call
  //     fetchTrip();

  //     // Poll every 10 seconds
  //     const intervalId = setInterval(fetchTrip, 10000);

  //     // Cleanup on unmount
  //     return () => clearInterval(intervalId);
  //   }, [apiKey, trip_Id, driverId, navigate]);

  const handleLogoutClick = () => {
    localStorage.setItem("trip_terminate", JSON.stringify(false));

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const navigateToHome = () => {
    sessionStorage.removeItem("trip_id");
    localStorage.setItem("trip_terminate", JSON.stringify(false));
    navigate("/home");
  };

  const handleLogoutSubmit = async (email, password) => {
    try {
      let data = {
        is_fr: 1,
        u_email: email,
        u_password: password,
        is_login: 0,
      };

      const response = await ApiService.post(`${USER_LOGIN}`, data);
      if (response.status === 200) {
        disableFullScreenMode();
        let user_email = sessionStorage.getItem("email");
        if (email.toLowerCase() === user_email.toLowerCase()) {
          sessionStorage.clear();
          navigate("/login");
          toast.success("Logged out successfully");
        }
      }
    } catch (error) {
      console.log("Error:", error.message);
      toast.error("Invalid credentials");
    } finally {
      handleCloseModal();
    }
  };

  const printPdf = useReactToPrint({
    content: () => componentRef.current,
  });

  // print invoice handler
  const handlePrintPdf = () => {
    if (componentRef.current && isPrintToogle) {
      printPdf();
    }
  };

  const enableFullScreenMode = () => {
    var elem = document.documentElement;

    // Check for fullscreen support in different browsers and request fullscreen
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  const disableFullScreenMode = () => {
    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape" && document.fullscreenElement) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (!userId) {
      navigate("/login");
    } else {
      enableFullScreenMode();
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [userId]);

  const timerRef = useRef(null);


  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(navigateToHome, 3 * 60 * 1000); // 3 minutes
  };

  useEffect(() => {
    const handleActivity = () => resetTimer();

    // Listen for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Set the initial timer
    resetTimer();

    // Cleanup event listeners and timer on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     if (isPrintToogle) {
  //       handlePrintPdf();
  //     }
  //   }, 1000);

  //   // Cleanup function to clear the timeout if the component unmounts
  //   return () => clearTimeout(timeoutId);
  // }, [isPrintToogle, handlePrintPdf]); // Add dependencies that can change

  const openDetailHandler = () => {
    setShowDetails(true);
  };

  const closeDetailHandler = () => {
    setShowDetails(false);
  };
  const bookingHistory = () => {
    navigate("/booking-history");
  };
  return tripDetails ? (
    <>
      {" "}
      {showDetails && (
        <SendMessageWithDriverDetail
          closeDetailHandler={closeDetailHandler}
          otp={tripDetails.otp}
          tripDetails={tripDetails}
        />
      )}
      <div className="kiosk-app-container">
      <div className="booking-button-trip" onClick={bookingHistory}>
        <button >
          <svg
            height="26"
            viewBox="0 0 25 25"
            width="26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="History" fill="#d4a73e">
              <path d="m14.329 19.677h-8.109a2.5 2.5 0 0 1 -2.5-2.5v-11.72a2.5 2.5 0 0 1 2.5-2.5h9.6a2.5 2.5 0 0 1 2.5 2.5v9.52a.5.5 0 0 1 -1 0v-9.52a1.484 1.484 0 0 0 -1.5-1.5h-9.6a1.484 1.484 0 0 0 -1.5 1.5v11.72a1.484 1.484 0 0 0 1.5 1.5h8.109a.5.5 0 0 1 0 1z" />
              <path d="m14.565 7.562h-7.091a.5.5 0 0 1 0-1h7.091a.5.5 0 0 1 0 1z" />
              <path d="m14.565 10.4h-7.091a.5.5 0 1 1 0-1h7.091a.5.5 0 0 1 0 1z" />
              <path d="m14.565 13.236h-7.091a.5.5 0 0 1 0-1h7.091a.5.5 0 1 1 0 1z" />
              <path d="m12.939 16.077h-5.469a.5.5 0 0 1 0-1h5.469a.5.5 0 0 1 0 1z" />
              <path d="m17.5 22.043a3.781 3.781 0 1 1 3.78-3.781 3.785 3.785 0 0 1 -3.78 3.781zm0-6.561a2.781 2.781 0 1 0 2.78 2.78 2.783 2.783 0 0 0 -2.78-2.78z" />
              <path d="m16.582 19.121a.5.5 0 0 1 -.182-.966l.6-.234v-1.158a.5.5 0 0 1 1 0v1.5a.5.5 0 0 1 -.318.466l-.918.359a.506.506 0 0 1 -.182.033z" />
            </g>
          </svg>
        </button>
      </div>
        <div className="logout-button">
          <button type="button" className="btn" onClick={handleLogoutClick}>
            <i className="fa fa-power-off" aria-hidden="true"></i>
          </button>
        </div>
        <div className="form-container col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "15px",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                maxWidth: "250px",
                height: "120px", // corrected 'Height' to 'height'
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
                overflow: "hidden", // ensures the image doesn't overflow
              }}
            >
              <img
                src="images/muscat.png"
                alt="logo"
                style={{
                  objectFit: "contain",
                  width: "80%", // ensures the image covers the full width of the container
                  height: "80%", // ensures the image fills the container height
                }}
              />
            </span>
          </div>
          <div
            style={{
              fontSize: 20,
              fontWeight: 600,
              color: "black",
              textAlign: "center",
              marginRight: 20,
              marginBottom: 30,
              animation: "blinker 1.5s linear infinite", // Add this line
            }}
          >
            Please take a screenshot of these trip details.
          </div>
          <div className="trip-details-container">
            {/*           <h2>Driver Details</h2>
             */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <span>
                <img
                  src={`https://app.taximuscat.com/images/originals/${tripDetails.Driver.d_profile_image_path}`}
                  style={{
                    width: 200,
                    height: 200,
                    border: "2px solid #6d6a6a",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
              </span>
            </div>
            <table className="table table-bordered" style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th scope="row" style={{ width: "50%", fontWeight: 500 }}>
                    OTP / كلمة المرور لمرة واحدة
                  </th>
                  <td style={{ width: "50%" }}>{tripDetails.otp}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%", fontWeight: 500 }}>
                    Driver Name / اسم السائق
                  </th>
                  <td style={{ width: "50%" }}>{tripDetails.Driver?.d_name}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%", fontWeight: 500 }}>
                    Driver Contact / رقم الاتصال بالسائق
                  </th>
                  <td style={{ width: "50%" }}>
                    {tripDetails.Driver?.d_phone}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%", fontWeight: 500 }}>
                    Vehicle Name / اسم المركبة
                  </th>
                  <td style={{ width: "50%" }}>
                    {tripDetails.Driver?.car_name}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%", fontWeight: 500 }}>
                    Vehicle Model / طراز السيارة
                  </th>
                  <td style={{ width: "50%" }}>
                    {tripDetails.Driver?.car_model}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "50%", fontWeight: 500 }}>
                    Vehicle Reg No / رقم مسجل المركبات
                  </th>
                  <td style={{ width: "50%" }}>
                    {tripDetails.Driver?.car_reg_no}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <h4 style={{ textAlign: "start" }}>
                {" "}
                Happy Journey… Thank you for using our service!
                <br />
                رحلة سعيدة… شكرًا لاستخدامكم خدمتنا
              </h4>
              <p style={{ textAlign: "start", fontSize: "16" }}>
                *Kindly note that the OTP will expire in 120 minutes
                <br /> يرجى ملاحظة أن كلمة المرور لمرة واحدة سينتهي بعد 120 دقيقة
              </p>
              <p
                style={{ textAlign: "start", fontSize: "16", paddingBottom: 3 }}
              >
                **You are not obliged for payment if no trip is availed
                <br /> أنت غير ملزم بالدفع إذا لم تتم الاستفادة من الرحلة
              </p>
              <p
                style={{
                  textAlign: "start",
                  fontSize: "16",
                  paddingBottom: 20,
                }}
              >
                ***Fare may vary if Vehicle Type is changed
                <br /> قد تتغير الأجرة إذا تم تغيير نوع المركبة
              </p>
            </div>
          </div>
          <div
            className=""
            style={{
              gap: 20,
              marginBottom: 30,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <button className="share-btn" onClick={() => openDetailHandler()}>
              Send To{" "}
              <img
                src="images/wa.svg"
                alt="logo"
                style={{
                  objectFit: "contain",
                  width: 35, // ensures the image covers the full width of the container
                  height: 35, // ensures the image fills the container height
                }}
              />
            </button>
          </div>

          <div className="button-space-between" style={{ gap: 20 }}>
            <button className="book-btn" onClick={navigateToHome}>
              Create New Trip / أنشئ رحلة جديدة
            </button>
            {isPrintToogle && (
              <button className="book-btn" onClick={handlePrintPdf}>
                Print Receipt / طباعة الإيصال
              </button>
            )}
          </div>
        </div>
        <div className="display-none">
          <div ref={componentRef}>
            <ReceiptBody tripDetails={tripDetails} />
          </div>
        </div>
        <LogoutModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleLogoutSubmit}
        />
      </div>
    </>
  ) : (
    <>
      <div className="kiosk-app-container">
        <div className="logout-button">
          <button type="button" className="btn" onClick={handleLogoutClick}>
            <i className="fa fa-power-off" aria-hidden="true"></i>
          </button>
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            textAlign: "center",
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="form-container col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8"
        >
          Please Wait...
        </div>

        <LogoutModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleLogoutSubmit}
        />
      </div>
    </>
  );
};

export default KioskTripReqInfo;
