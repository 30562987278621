import { Alert } from "@mui/material";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import KioskLoginPage from "../pages/kiosk-app/auth/login";
import KioskHomePage from "../pages/kiosk-app/home";
import KioskTripInfo from "../pages/kiosk-app/trip-info";
import KioskTripReqInfo from "../pages/kiosk-app/req-trip-info";
import { Navigate } from "react-router-dom";
import ApiService from "../utils/Api";
import { GET_USER, USER_LOGIN } from "../utils/Endpoint";
import KioskHotelBookingForm from "../components/forms/KioskBookingHotelForm";
import BookingHistory from "../pages/booking-history/bookingHistory";

//const HomePage = lazy(() => import("../pages/home"));
//const AboutPage = lazy(() => import("../pages/about"));
//const ServicePage = lazy(() => import("../pages/services"));
const TermConditionPage = lazy(() => import("../pages/term-condition"));
const PrivacyPolicyPage = lazy(() => import("../pages/privacy-policy"));
//const LoginPage = lazy(() => import("../pages/login"));
//const MyAccountPage = lazy(() => import("../pages/my-account"));
//const BookingPage = lazy(() => import("../pages/booking"));
//const ContactUsPage = lazy(() => import("../pages/contact"));
//const LoginWithPhone = lazy(() => import("../components/login/LoginWithPhone"));
//const Register = lazy(() => import("../components/register/Register"));
const ErrorPage = lazy(() => import("../pages/error"));

const MainRoutes = ({ setIsAuthenticated }) => {
  const [kiosk_type, setKiosk_Type] = useState("");

  useEffect(() => {
    const ridetype = sessionStorage.getItem("kiosk_ride_type");

    setKiosk_Type(ridetype); // Update state when component mounts
  }, []); // Empty dependency array to ensure this runs only once after the initial render

  const isAuthenticated = () => {
    return !!sessionStorage.getItem("auth"); // For example, you can check for a token or other auth state
  };

  const handleLogoutSubmit = async (email, password) => {
    try {
      let data = {
        is_fr: 1,
        u_email: email,
        u_password: password,
        is_login: 0,
      };

      const response = await ApiService.post(`${USER_LOGIN}`, data);
      if (response.status === 200) {
        let user_email = sessionStorage.getItem("email");
        if (email.toLowerCase() === user_email.toLowerCase()) {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/login";
        }
      }
    } catch (error) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/login";
      console.log("Error:", error.message);
    } finally {
    }
  };

  const getUser = async () => {
    let data = {
      user_id: localStorage.getItem("user_id"),
      api_key: localStorage.getItem("key"),
    };

    const config = {
      headers: {
        "Disable-Loader": true, // Disable the loader for this specific call
      },
    };

    if (localStorage.getItem("key")) {
      try {
        const response = await ApiService.post(`${GET_USER}`, data, config);

        if (response.status === 200) {
          const { resDecrypt } = response.data;
          const userResponse = resDecrypt?.response?.[0];

          if (userResponse) {
            // If API key from response does not match the stored key, log the user out
            if (userResponse.api_key !== localStorage.getItem("key")) {
              await handleLogoutSubmit(
                userResponse.u_email,
                userResponse.u_password
              );
              // Optionally, redirect to login page after logout
              window.location.href = "/login";
            }
          } else {
            console.error("Invalid response format from server.");
          }
        } else if (response.status === 401) {
          // If unauthorized, clear storage and redirect to login
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/login";
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
      } catch (error) {
        if ("Request failed with status code 401" == error.message) {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/login";
        }

        console.error("Error fetching user data:", error.message);
        // Optionally, handle specific error scenarios like network issues or timeouts
      }
    }
  };

  setInterval(() => {
    getUser();
  }, 30000);

  const AuthRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" replace />;
  };

  const PublicRoute = ({ children }) => {
    return isAuthenticated() ? <Navigate to="/home" replace /> : children;
  };

  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <KioskLoginPage />
          </PublicRoute>
        }
      />
      <Route
        path="/term-condition"
        element={
          <PublicRoute>
            <TermConditionPage />
          </PublicRoute>
        }
      />

      {/* Protected Routes */}
      <Route
        path="/home"
        element={
          <AuthRoute>
            <KioskHomePage />
          </AuthRoute>
        }
      />
      <Route
        path="/booking-history"
        element={
          <AuthRoute>
            <BookingHistory />
          </AuthRoute>
        }
      />
      <Route
        path="/trip-info"
        element={
          <AuthRoute>
            <KioskTripInfo />
          </AuthRoute>
        }
      />
      <Route
        path="/request-trip-info"
        element={
          <AuthRoute>
            <KioskTripReqInfo />
          </AuthRoute>
        }
      />

      {/* Root Route */}
      <Route
        path="/"
        element={
          isAuthenticated() ? (
            <Navigate to="/home" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />

      {/* Catch-all for invalid routes */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default MainRoutes;
