import React, { useState, useEffect, useRef } from "react";

const IdleVideo = () => {
  const [isIdle, setIsIdle] = useState(false);
  const idleTimeoutRef = useRef(null);
  const idleTime = 60000; // 60 seconds of inactivity
  const videoRef = useRef(null);

  // Function to reset idle timer
  const resetIdleTimer = () => {
    setIsIdle(false);
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
    }
    idleTimeoutRef.current = setTimeout(() => {
      setIsIdle(true);
    }, idleTime);
  };


  useEffect(() => {
    const disableScroll = () => {
      document.body.style.overflowY = "hidden";
    };
    
    const enableScroll = () => {
      document.body.style.overflowY = "auto"; // Restore scrolling
    };
    

    if (isIdle) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll(); // Clean up when the component unmounts
    };
  }, [isIdle]);


  // Set up event listeners for user activity
  useEffect(() => {
    // Reset the timer on activity
    const events = ["mousemove", "keydown", "scroll", "touchstart"];
    events.forEach((event) => window.addEventListener(event, resetIdleTimer));

    // Clean up event listeners on unmount
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetIdleTimer)
      );
      if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
    };
  }, []);

  // Effect to start video playback when idle
  useEffect(() => {
    if (isIdle && videoRef.current) {
      videoRef.current.play();
    }
  }, [isIdle]);
  
  const [videoUrl, setVideoUrl] = useState(null);

  const fetchVideoUrl = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/constantapi/getbgvideourl`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Adjust if necessary
        },
        body: JSON.stringify({
          // Any body content you need to send in the POST request
          // If the API doesn't require a body, you can omit this part
        }),
      });

      const data = await response.json();
       
      // Assuming resDecrypt is already available in the response
      if (data.resDecrypt && data.resDecrypt.response && data.resDecrypt.response.length > 0) {
        const url = data.resDecrypt.response[0].svalue;
        setVideoUrl(url);
      }
    } catch (error) {
      console.error('Error fetching video URL:', error);
    }
  };

  useEffect(() => {
    // Initial fetch when component mounts
    fetchVideoUrl();

    // Set interval to fetch every 30 minutes (1800000 ms)
    const intervalId = setInterval(fetchVideoUrl, 1800000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // console.log(videoUrl)
 
  return (
    <div className="idle" style={{ display: isIdle ? "block" : "none" }}>
      <video
        ref={videoRef}
        key={videoUrl} // Force reloading when videoUrl changes
        autoPlay
        muted // Add muted attribute to allow autoplay
        loop // Optional: Use this to loop the video continuously
        className="full-screen-video"
      >
        <source
          src={videoUrl ? videoUrl : "images/screen.mp4"}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default IdleVideo;
