import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainRoutes from "./routes/MainRoutes";
import IdleVideo from "./components/screenIdle";
import { useLocation, useNavigate } from "react-router-dom";
const useRestrictNavigation = () => {

  useEffect(() => {
    return () => {
      if (window.history.action === 'POP') {
        window.history.go(1);
      }
    };
  }, [window.history]);


  useEffect(() => {
    // Function to handle the popstate event and prevent navigation
    const handlePopState = (event) => {
      // Push the current state to history to prevent going back
      window.history.pushState(null, document.title, window.location.href);
      window.history.forward();
      window.history.go(1);

    };

    // Push initial state to history when component mounts
    window.history.pushState(null, document.title, window.location.href);
    window.history.forward();
    window.history.go(1);

    // Listen for popstate (back/forward navigation) attempts
    window.addEventListener("popstate", handlePopState);

    // Prevent right-click context menu (optional but adds another layer of restriction)
    window.oncontextmenu = (e) => e.preventDefault();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);

    };
  }, []);
};



function App() {
  useRestrictNavigation()
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("auth") ? true : false
  );

  useEffect(() => {
    setIsAuthenticated(sessionStorage.getItem("auth") ? true : false);
  }, []);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  // Callback when the Google Maps script is loaded
  const handleScriptLoad = () => {
    setScriptLoaded(true);
  };
  return (
    <>
      <IdleVideo />
      <MainRoutes setIsAuthenticated={setIsAuthenticated} />{" "}
      {/* Pass setIsAuthenticated to the MainRoutes component */}
      <ToastContainer />
    </>
  );
}

export default App;