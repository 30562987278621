import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { KioskSMS } from "../../utils/Endpoint";
import ApiService from "../../utils/Api";
import { toast } from "react-toastify";
import moment from "moment";

export const SendMessageWithDriverDetail = ({ closeDetailHandler, otp, tripDetails }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(""); // To store country code
  const [errors, setErrors] = useState({});
  const apiKey = sessionStorage.getItem("key");

  const handleClearName = () => {
    setName("");
    if (errors.Phone) setErrors((prev) => ({ ...prev, Phone: "" }));
  };

  const onPhoneChange = (phone) => {
    setPhone(phone);

    // Parse the phone number to get the country code
    const parsedNumber = parsePhoneNumberFromString(`+${phone}`);
    if (parsedNumber && parsedNumber.countryCallingCode) {
      setCountryCode(parsedNumber.countryCallingCode);
    }
  };

  /*   Taxi Muscat

XXXX is your OTP.

Ride Details : 
Date : [Date]
Driver Name : [Driver Name]
Driver Contact : [Driver Contact]
Vehicle Type : [ Category ]
Vehicle Name : [Vehicle Name]
Vehicle Model : [Vehicle Model]
Vehicle Registration Number : [Vehicle Registration Number]
From Address : [Pickup Address]
To Address : [Drop-off Address]

Please share the OTP with the Taxi driver to start your Trip.
Happy Journey. Thank you for using our services.
Kindly Note:

1. The Ride Code will expire in 120 minutes.
2. You are not obliged for payment if no trip is availed. 
3. The fare may vary if vehicle type is changed.
 */




  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6379; // Earth's radius in kilometers
    const toRadians = (deg) => (deg * Math.PI) / 180;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in kilometers
  };

  const calculateDuration = (distance, averageSpeed = 40) => {
    return distance / averageSpeed; // Duration in hours
  };


  const calculateEstimatedArrivalTime = (orLat, orLng, deLat, deLng) => {
    const distance = calculateDistance(orLat, orLng, deLat, deLng); // In kilometers
    const duration = calculateDuration(distance); // Duration in hours

    const durationInMinutes = Math.round(duration * 60); // Convert to minutes
    return `${durationInMinutes} mins`;
  };

  // Example usage
  const orLat = tripDetails?.Driver?.d_lat;
  const orLng = tripDetails?.Driver?.d_lng;
  const deLat = tripDetails?.trip_scheduled_pick_lat;
  const deLng = tripDetails?.trip_scheduled_pick_lng;

  const estimatedArrivalTime = calculateEstimatedArrivalTime(orLat, orLng, deLat, deLng);





  const sendSMSHandler = async (event) => {
    event.preventDefault();

    if (!phone) {
      toast.error("Please enter your phone number");
      return;
    }

    // let setEstimatedArrivalTime

    // const response = await ApiService.post(
    //   `${process.env.REACT_APP_DISTANCE_MATRIX_URL}?orlat=${tripDetails?.Driver.d_lat}&orlng=${tripDetails?.Driver.d_lng}&delat=${tripDetails?.trip_scheduled_pick_lat}&delng=${tripDetails?.trip_scheduled_pick_lng}`
    // );
    // if (response.status === 200) {
    //   let result = response.data;
    //   // let distance = result?.routes[0]?.legs[0]?.distance?.text;
    //   let duration = result?.routes[0]?.legs[0]?.duration?.text;
    //   // let calculatedDistance =
    //   //   result?.routes[0]?.legs[0]?.distance?.value / 1000;
    //   // let calculatedDirection =
    //   //   result?.routes[0]?.legs[0]?.duration?.value / 60;
    //   const distance = calculateDistance(orLat, orLng, deLat, deLng); // In kilometers

    //   setEstimatedArrivalTime = duration

    //   console.log(estimatedArrivalTime,"t,",distance, "km", setEstimatedArrivalTime)
    // }

    var e = new Date(tripDetails.trip_date);
    var utc_offset_gmt = e.getTimezoneOffset();
    e.setMinutes(e.getMinutes() - utc_offset_gmt);
    const date= moment(e).format("YYYY-MM-DD");
    const time = moment(e).format("hh:mm a");
    const formattedDateTime = `${date} ${time}`;

    const params = {
      lang: "en",
      msg: 651957,
      otp: otp,
      ph: phone,
      c_code: countryCode,
      date: formattedDateTime,
      driver_name: tripDetails?.Driver?.d_name,
      driver_contact: tripDetails?.Driver?.d_phone ? tripDetails?.Driver?.d_phone : "Not available",
      vehicle_type: tripDetails.cat_name,
      vehicle_name: tripDetails?.Driver?.car_name,
      vehicle_model: tripDetails?.Driver?.car_model,
      vehicle_registration_number: tripDetails?.Driver?.car_reg_no ? tripDetails?.Driver?.car_reg_no : "Not available",
      from_address: tripDetails.trip_from_loc,
      to_Address: tripDetails.trip_to_loc ? tripDetails.trip_to_loc : "Not available",
      estimated_fare: `OMR ${tripDetails.trip_pay_amount?.toFixed(2) || "0.00"}`, // Default value for trip_pay_amount
      estimated_time_of_arrival: estimatedArrivalTime || "Not available",
      tracking_url: `${process.env.REACT_APP_TRACKING_URL}?trip=${tripDetails.trip_id}`
    };

    try {
      const response = await ApiService.post(`${KioskSMS}?`, params);

      if (response.status === 200) {
        // toast.success(
        //   "Please check if your WhatsApp details have been sent successfully."
        // );
        closeDetailHandler();
      }
    } catch (error) {
      console.error("Try again", error);
      toast.error("Failed to send message. Please try again.");
    }

    const data = {
      lang: "ar",
      msg: 651965,
      otp: otp,
      ph: phone,
      c_code: countryCode,
      date: formattedDateTime,
      driver_name: tripDetails?.Driver?.d_name,
      driver_contact: tripDetails?.Driver?.d_phone ? tripDetails?.Driver?.d_phone : "Not available",
      vehicle_type: tripDetails.cat_name,
      vehicle_name: tripDetails?.Driver?.car_name,
      vehicle_model: tripDetails?.Driver?.car_model,
      vehicle_registration_number: tripDetails?.Driver?.car_reg_no ? tripDetails?.Driver?.car_reg_no : "Not available",
      from_address: tripDetails.trip_from_loc,
      to_Address: tripDetails.trip_to_loc ? tripDetails.trip_to_loc : "Not available",
      estimated_fare: `OMR ${tripDetails.trip_pay_amount?.toFixed(2) || "0.00"}`, // Default value for trip_pay_amount
      estimated_time_of_arrival: estimatedArrivalTime || "Not available",
      tracking_url: `${process.env.REACT_APP_TRACKING_URL}?trip=${tripDetails.trip_id}`
    };

    try {
      const response = await ApiService.post(`${KioskSMS}?`, data);

      if (response.status === 200) {
        toast.success(
          "Please check your WhatsApp details have been sent successfully."
        );
        closeDetailHandler();
      }
    } catch (error) {
      console.error("Try again", error);
      toast.error("Failed to send message. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <div className="backdrop">
        <div
          className="center-screen"
          style={{ position: "absolute", minWidth: "375px" }}
        >
          <div
            className="col-md-auto col-sm-auto form-group"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <button
              className="book-btn"
              style={{
                borderRadius: "50%",
                fontSize: 18,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 10,
                right: 10,
                height: 40,
                width: 40,
                textAlign: "center",
              }}
              onClick={() => closeDetailHandler()}
            >
              x
            </button>
          </div>
          <div className="offercount">
            <div
              style={{
                textAlign: "center",
                color: "black",
                marginBottom: 20,
              }}
            >
              <h3>Send to WhatsApp</h3>
            </div>
            <div style={{ display: "grid" }}>
              <div
                className="trip-details-container"
                style={{ display: "grid", position: "relative" }}
              >
                <form onSubmit={sendSMSHandler}>
                  {/* <div className="col-md-auto col-sm-auto">
                    <strong className="location-label">
                      <span>Name / الاسم</span>
                    </strong>
                    <div className="field-holder field-holderb border">
                      <input
                        type="text"
                        placeholder="Please Enter Your Name"
                        className="location-search-input"
                        value={name.address}
                        onChange={(e) =>
                          setName( e.target.value)
                        }
                      />
                      {name.address && (
                        <span
                          className="fas fa-times-circle"
                          style={{
                            marginRight: "0px",
                            fontSize: "17px",
                            cursor: "pointer",
                          }}
                          onClick={handleClearName}
                        ></span>
                      )}
                    </div>
                  </div> */}

                  <div className="col-md-auto col-sm-auto">
                    <strong className="location-label">
                      <span>Phone / الهاتف</span>
                    </strong>
                    <div className="field-holder ph field-holderb border">
                      <PhoneInput
                        country={"om"} // Sets Oman as the default country
                        value={phone}
                        onChange={onPhoneChange}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        inputClass="location-search-input"
                      />
                    </div>
                  </div>
                  <div className="col-md-auto col-sm-auto form-group">
                    <button
                      className="book-btn"
                      style={{ width: "100%", marginTop: "7px" }}
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
