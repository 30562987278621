import React, { useEffect, useState } from "react";
import KioskBookingForm from "../../../components/forms/KioskBookingForm";
import "../kiosk-app.css";
import { USER_LOGIN } from "../../../utils/Endpoint";
import ApiService from "../../../utils/Api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogoutModal from "../components/LogoutModal";
import KioskHotelBookingForm from "../../../components/forms/KioskBookingHotelForm";

const KioskHomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId] = useState(sessionStorage.getItem("userid") || "");
  const navigate = useNavigate();
  const location = useLocation();
  const kiosk_type =
    location.state?.kiosk_type == undefined
      ? sessionStorage.getItem("kiosk_ride_type")
      : location.state?.kiosk_type; // Safely access the passed valu
  const handleLogoutClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLogoutSubmit = async (email, password) => {
    try {
      let data = {
        is_fr: 1,
        u_email: email,
        u_password: password,
        is_login: 0,
      };

      const response = await ApiService.post(`${USER_LOGIN}`, data);
      if (response.status === 200) {
        disableFullScreenMode();
        let user_email = sessionStorage.getItem("email");
        if (email.toLowerCase() === user_email.toLowerCase()) {
          sessionStorage.clear();
          navigate("/login");
          toast.success("Logged out successfully");
        }
      }
    } catch (error) {
      console.log("Error:", error.message);
      toast.error("Invalid credentials");
    } finally {
      handleCloseModal();
    }
  };

  const enableFullScreenMode = () => {
    var elem = document.documentElement;

    // Check for fullscreen support in different browsers and request fullscreen
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  // const enableFullScreenMode = () => {
  //   if (!document.fullscreenElement) {
  //     document.documentElement.requestFullscreen();
  //   }
  // };

  const disableFullScreenMode = () => {
    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape" && document.fullscreenElement) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (!userId) {
      navigate("/login");
    } else {
      enableFullScreenMode();
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [userId]);

  const bookingHistory = () => {
    navigate("/booking-history");
  };

  return (
    <div className="kiosk-app-container">
      <div className="booking-button " onClick={bookingHistory}>
        <Link to={"/booking-history"}>
          <svg
            height="24"
            viewBox="0 0 25 25"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="History" fill="#d4a73e">
              <path d="m14.329 19.677h-8.109a2.5 2.5 0 0 1 -2.5-2.5v-11.72a2.5 2.5 0 0 1 2.5-2.5h9.6a2.5 2.5 0 0 1 2.5 2.5v9.52a.5.5 0 0 1 -1 0v-9.52a1.484 1.484 0 0 0 -1.5-1.5h-9.6a1.484 1.484 0 0 0 -1.5 1.5v11.72a1.484 1.484 0 0 0 1.5 1.5h8.109a.5.5 0 0 1 0 1z" />
              <path d="m14.565 7.562h-7.091a.5.5 0 0 1 0-1h7.091a.5.5 0 0 1 0 1z" />
              <path d="m14.565 10.4h-7.091a.5.5 0 1 1 0-1h7.091a.5.5 0 0 1 0 1z" />
              <path d="m14.565 13.236h-7.091a.5.5 0 0 1 0-1h7.091a.5.5 0 1 1 0 1z" />
              <path d="m12.939 16.077h-5.469a.5.5 0 0 1 0-1h5.469a.5.5 0 0 1 0 1z" />
              <path d="m17.5 22.043a3.781 3.781 0 1 1 3.78-3.781 3.785 3.785 0 0 1 -3.78 3.781zm0-6.561a2.781 2.781 0 1 0 2.78 2.78 2.783 2.783 0 0 0 -2.78-2.78z" />
              <path d="m16.582 19.121a.5.5 0 0 1 -.182-.966l.6-.234v-1.158a.5.5 0 0 1 1 0v1.5a.5.5 0 0 1 -.318.466l-.918.359a.506.506 0 0 1 -.182.033z" />
            </g>
          </svg>
        </Link>
      </div>
      <div className="logout-button">
        <i
          className="fa fa-power-off"
          aria-hidden="true"
          onClick={handleLogoutClick}
        ></i>
      </div>
      <div className="form-container col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8">
        {kiosk_type?.toLowerCase() == "kiosk" ? (
          <KioskBookingForm />
        ) : (
          <KioskHotelBookingForm />
        )}
      </div>
      <LogoutModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleLogoutSubmit}
      />
    </div>
  );
};

export default KioskHomePage;
