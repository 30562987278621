import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { KioskSMS } from "../../utils/Endpoint";
import ApiService from "../../utils/Api";
import { toast } from "react-toastify";

export const LoginPopup = ({ ProcedLogin, closeHandler }) => {
  const apiKey = sessionStorage.getItem("key");

  return (
    <React.Fragment>
      <div className="backdrop">
        <div
          className="center-screen"
          style={{ position: "absolute", minWidth: "375px" }}
        >
          {/*    <div
            className="col-md-auto col-sm-auto form-group"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <button
              className="book-btn"
              style={{
                borderRadius: "50%",
                fontSize: 18,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 10,
                right: 10,
                height: 40,
                width: 40,
                textAlign: "center",
              }}
              onClick={() => closeDetailHandler()}
            >
              x
            </button>
          </div> */}
          <div className="offercount">
            <div
              style={{
                textAlign: "center",
                color: "black",
                marginBottom: 20,
              }}
            >
              <h3>Alert / تنبيه</h3>
              <p>
                To log in, click "Continue." Please note that this will log out
                any other user currently using these credentials on another
                device.{" "}
              </p>
              <p>
                لذلك قم بتسجيل الدخول، انقر فوق "متابعة". يرجى ملاحظة أن هذا
                سيؤدي إلى تسجيل خروج أي مستخدم آخر يستخدم حاليًا بيانات الاعتماد
                هذه على جهاز آخر.
              </p>
            </div>
            <div style={{ display: "grid" }}>
              <div
                className="trip-details-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {" "}
                <div className="col-md-auto col-sm-auto form-group">
                  <button
                    className="book-btn"
                    style={{ width: "100%", marginTop: "7px" }}
                    onClick={closeHandler}
                  >
                    Close / إنهاء
                  </button>
                </div>
                <div className="col-md-auto col-sm-auto form-group">
                  <button
                    className="book-btn"
                    style={{ width: "100%", marginTop: "7px" }}
                    type="submit"
                    onClick={ProcedLogin}
                  >
                    Continue / استمر بالمحاولة
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
