import React, { useEffect, useState, useRef } from "react";
import { CSVLink } from "react-csv";
import Modal from "react-modal";
import "./booking.css";
import moment from "moment";
import ReactToPrint from "react-to-print";
import DataTable from "react-data-table-component";
import { fontSize } from "@mui/system";
import ApiService from "../../utils/Api";
import { GET_TRIPS, UPDATE_TRIP, USER_LOGIN } from "../../utils/Endpoint";
import LogoutModal from "../kiosk-app/components/LogoutModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
const BookingHistory = () => {
  const statusValues = {
    request: "Request",
    assigned: "Partner Assigned",
    accept: "Partner Accepted",
    arrive: "Partner Arrived",
    begin: "Begin",
    completed: "Completed",
    cancel: "Cancelled",
    p_cancel_pickup: "Partner Cancel at Pickup Location",
    p_cancel_drop: "Partner Cancel at Drop Location",
    expired: "Expired",
    paid_cancel: "Cancelled",
  };

  const csvstatus = { Request: "request" };

  const apikey = sessionStorage.getItem("key");
  const userid = sessionStorage.getItem("userid");
  const city_cur = sessionStorage.getItem("city_cur");
  const parentid = sessionStorage.getItem("parent_id");
  const [bookingtable, setbookingtable] = useState([]);
  const [openmodal, setopenmodal] = useState(false);
  const [ide, setide] = useState("");
  const [msg, setmsg] = useState(false);
  const [reason, setreason] = useState("");
  const [cancelData, setCancelData] = useState("");

  const [search, setsearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const componentRef = useRef();
  const [userId] = useState(sessionStorage.getItem("userid") || "");
  const navigate = useNavigate();
  useEffect(() => {
    getTripHistory();
  }, []);

  const handleLogoutClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleLogoutSubmit = async (email, password) => {
    try {
      let data = {
        is_fr: 1,
        u_email: email,
        u_password: password,
        is_login: 0,
      };

      const response = await ApiService.post(`${USER_LOGIN}`, data);
      if (response.status === 200) {
        disableFullScreenMode();
        let user_email = sessionStorage.getItem("email");
        if (email.toLowerCase() === user_email.toLowerCase()) {
          sessionStorage.clear();
          navigate("/login");
          toast.success("Logged out successfully");
        }
      }
    } catch (error) {
      console.log("Error:", error.message);
      toast.error("Invalid credentials");
    } finally {
      handleCloseModal();
    }
  };

  const handleCancelRide = async () => {
    try {
      // "Base Fare = Minimum fare + (Fare per km * Actual distance count ) + (Fare per min * Time(Estimated minutes))
      // Estimated Fare = Base Fare + GST amount applicable "

      // let jtf = (
      //   parseInt(fareMatrix.basePrice) +
      //   (fareMatrix.baseFarePerKm *
      //     (distanceMatrix.calculatedDistance - fareMatrix.catBaseIncludes) +
      //     fareMatrix.farePerMin * distanceMatrix.calculatedDirection)
      // ).toFixed(2);

      let data = {
        trip_cancel: "Request",
        trip_id: cancelData.trip_id,
        trip_status: "cancel",
        trip_reason: reason,
        cancelled_by: "Rider",
        user_id: userId,
        cancelled_details: {
          cancelled_location: cancelData.trip_from_loc,
          cancelled_lat: cancelData.trip_scheduled_pick_lat,
          cancelled_lng: cancelData.trip_scheduled_pick_lng,
          cancelled_timestamp: dayjs().tz("GMT").format("YYYY-MM-DD HH:mm:ss"),
        },
      };

      const response = await ApiService.post(
        `${UPDATE_TRIP}?api_key=${apikey}`,
        data
      );

      if (response.status === 200) {
        getTripHistory()
        setopenmodal(false)
        toast.success("Ride cancelled successfully")
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  const enableFullScreenMode = () => {
    var elem = document.documentElement;

    // Check for fullscreen support in different browsers and request fullscreen
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  const disableFullScreenMode = () => {
    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape" && document.fullscreenElement) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (!userId) {
      navigate("/login");
    } else {
      enableFullScreenMode();
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [userId]);

  const headers = [
    { label: "ID", key: "trip_id" },
    { label: "Date", key: "date" },
    { label: "Time", key: "timefor" },
    { label: "Pickup", key: "trip_from_loc" },
    { label: "DropOff", key: "trip_to_loc" },
    { label: "Total Fare", key: "trip_pay_amount" },
    { label: "Status", key: "status" },
    { label: "Remarks", key: "trip_reason" },
  ];
  // console.log(bookingtable)

  const getTripHistory = async () => {
    const data = {
      is_request: "0",
      offset: "0",
      statuses:
        "completed,paid_cancel,begin,assign,arrive,accept,request,paid,cancel,p_cancel_drop,p_cancel_pickup,expired",
    };

    try {
      const response = await ApiService.post(
        `${GET_TRIPS}?api_key=${apikey}&kiosk_id=${userid}&kiosk_owner_id=${parentid}`,
        data
      );
      if (response.status === 200) {
        if (response.data.resDecrypt.response) {
          setbookingtable(response.data.resDecrypt.response);
        }
        // console.log(response.data.response)
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  // let csvData = bookingtable?.map((it, ind) => {
  //   var e = new Date(it.trip_date);
  //   var utc_offset_gmt = e.getTimezoneOffset();
  //   e.setMinutes(e.getMinutes() - utc_offset_gmt);
  //   it.date = moment(e).format("YYYY-MM-DD");
  //   it.timefor = moment(e).format("hh:mm a");

  //   // it.date = moment(it.trip_date).format("YYYY-MM-DD");
  //   // it.timefor = moment(it.trip_date).format("hh:mm A");
  //   it.status = statusValues[it.trip_status];
  //   return it;
  // });

  let csvData = bookingtable
    ?.map((it, ind) => {
      var e = new Date(it.trip_date);
      var utc_offset_gmt = e.getTimezoneOffset();
      e.setMinutes(e.getMinutes() - utc_offset_gmt);
      it.date = moment(e).format("YYYY-MM-DD");
      it.timefor = moment(e).format("hh:mm a");

      it.status = statusValues[it.trip_status];

      // Only include data with the current date
      if (it.date === moment().format("YYYY-MM-DD")) {
        return it;
      }

      // Return null if not the current date (this will filter out unwanted data)
      return null;
    })
    .filter((it) => it !== null); // Filter out any null values (non-current date data)

  const [filter, setfilter] = useState(csvData);

  const handleFilter = (event) => {
    setsearch(event.target.value);
    const newData = csvData.filter((row) => {
      return row.trip_from_loc
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setfilter(newData);
  };
  const handleModal = (row) => {
    setopenmodal(true);
    setCancelData(row);
    setmsg(false);
  };
  const navigateToHome = () => {
    navigate("/home");
  };
  // const columns = [
  //     {
  //         name: "ID",
  //         selector: (row) => row.trip_id
  //     },
  //     {
  //         name: "Date",
  //         selector: (row) => row.date
  //     },
  //     {
  //         name: "Time",
  //         selector: (row) => row.timefor
  //     },
  //     {
  //         name: "Pickup",
  //         selector: (row) => row.actual_from_loc==null?row.trip_from_loc:row.actual_from_loc
  //     },
  //     {
  //         name: "DropOff",
  //         selector: (row) => row.actual_to_loc==null?row.trip_to_loc:row.actual_to_loc
  //     },
  //     {
  //         name: "Status",
  //         selector: (row) => statusValues[row.trip_status]
  //     },
  //     {
  //         name:"Total Fare",
  //         selector:(row)=>row.trip_pay_amount
  //     },
  //     {
  //         name: "",
  //         selector: (row) => row.trip_status === "request" ? <input id="mybtn" data-toggle="modal" data-target="#myModal372"
  //             className="btn btn-danger" type="submit" onClick={(e) => handleModal(row.trip_id)} value="Cancel"
  //         /> : ""
  //     },
  //     {
  //         name: "Remarks",
  //         selector: (row) => row.trip_reason
  //     }
  // ]
  const columns = [
    {
      name: "Trip#",
      selector: (row) => (
        <div className="text-center">
          <div>#{row?.trip_id}</div>
          {/* <div>{row.timefor}</div> */}
        </div>
      ),
      //  row.trip_id
      width: "80px",
    },
    {
      name: "Date",
      selector: (row) => (
        <div className="text-center">
          <div style={{ paddingBottom: 5 }}>{row?.date}</div>
          <div>{row.timefor}</div>
        </div>
      ),
      width: "120px",
    },
    {
      name: "Locations",
      selector: (row) => {
        const pickup =
          row.actual_from_loc == null ? row.trip_from_loc : row.actual_from_loc;
        const dropOff =
          row.actual_to_loc == null ? row.trip_to_loc : row.actual_to_loc;
        return (
          <div
            style={{
              display: "flex !imporant",
              flexDirection: "column !imporant",
            }}
          >
            <div
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                whiteSpace: "pre-line !imporant",
              }}
            >
              <strong style={{ color: "darkgreen" }}>Pickup:</strong> {pickup}
            </div>
            <div style={{ paddingLeft: 0, marginBottom: "10px" }}>
              {" "}
              {dropOff ? (
                <div style={{ whiteSpace: "pre-line !imporant" }}>
                  <strong style={{ color: "darkred" }}>DropOff:</strong>{" "}
                  {dropOff}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
      // width: "200px",
      // height:"200px",
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <div
            style={{
              display: "flex !imporant",
              flexDirection: "column !imporant",
            }}
          >
            <div
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                whiteSpace: "pre-line !imporant",
              }}
            >
              <div
                style={{
                  color:
                    statusValues[row.trip_status] === "Completed"
                      ? "green"
                      : "darkred",
                }}
              >
                {statusValues[row.trip_status]}
              </div>
            </div>
            <div style={{ paddingLeft: 0, marginBottom: "10px" }}>
              <div style={{ whiteSpace: "pre-line !imporant" }}>
                {row.trip_reason && (
                  <div style={{ whiteSpace: "normal" }}>
                    <strong style={{ fontSize: "14px" }}>Remarks:</strong> {row.trip_reason}
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginLeft: "-10px", marginBottom: "10px" }}>
              {(row.trip_status === "request" || row.trip_status === "arrive" || row.trip_status === "accept") && (
                <input
                  id="mybtn"
                  data-toggle="modal"
                  data-target="#myModal372"
                  className="cancelBtn btn-danger btn"
                  type="submit"
                  onClick={(e) => handleModal(row)}
                  value="Cancel"
                />
              )}
            </div>
          </div>

          {/*    <div
            className="text-left"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0",
              margin: "auto",
            }}
          >
             <div style={{ marginTop: "10px", whiteSpace: "pre-line", color: statusValues[row.trip_status] === "Completed" ? "green" : "darkred" }}>
    {statusValues[row.trip_status]}
  </div>
  {row.trip_reason && (
    <div style={{ whiteSpace: "pre-line" }}>
      <strong>Remarks:</strong> {row.trip_reason}
    </div>
  )}
            {row.trip_status === "request" && (
              <input
                id="mybtn"
                data-toggle="modal"
                data-target="#myModal372"
                className="cancelBtn btn-danger btn"
                type="submit"
                onClick={(e) => handleModal(row.trip_id)}
                value="Cancel"
              />
            )}
          </div>*/}
        </>
      ),
      maxWidth: "300px",
    },
    {
      name: "Fare",
      selector: (row) => {
        return (
          <div className="text-center ">
            <strong style={{

              whiteSpace: "normal !imporant",
            }}>
              {row.trip_currency} {row.trip_pay_amount?.toFixed(2)}
            </strong>
          </div>
        );
      },
      width: "150px",
    },
  ];

  return (
    <div className="kiosk-app-container col-md-auto col-sm-auto" style={{ marginBottom: 50 }}>
      <div className="logout-button" style={{ zIndex: 99 }}>
        <button type="button" className="btn" onClick={handleLogoutClick}>
          <i className="fa fa-power-off" aria-hidden="true"></i>
        </button>
      </div>
      <div className=" col-xs-10 col-sm-10 col-md-12 col-lg-12 col-xl-12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="tab-content col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
          {msg ? (
            <div className="alert alert-success">
              Trip Cancelled Successfully
            </div>
          ) : null}
          <div
            class="tab-pane active"
            id="confirm_booking"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <div>
            <CSVLink
              filename={"booking-history.csv"}
              data={csvData}
              headers={headers}
            >
              <button className="btn btn-primary">CSV</button>
            </CSVLink>
            <ReactToPrint
              trigger={() => (
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Print
                </button>
              )}
              content={() => componentRef.current}
              pageStyle="print"
            />
          </div> */}
            {/* <div>
                        <input type="text" placeholder="search" value={search} onChange={handleFilter} />
                    </div> */}
          </div>

          <div
            ref={componentRef}
            style={{ marginTop: "70px", background: "white", borderRadius: 8 }}
          >
            {filter.length == 0 ? (
              <DataTable
                columns={columns}
                data={csvData}
                pagination
                fixedHeader
              />
            ) : (
              <DataTable
                className="my-custom-datatable"
                columns={columns}
                data={filter}
                pagination
                fixedHeader
              />
            )}
            <div style={{ padding: 10 }}>
              {" "}
              <button className="book-btn" onClick={navigateToHome}>
                Create New Trip / أنشئ رحلة جديدة
              </button>
            </div>
          </div>
        </div>

        <Modal
          isOpen={openmodal}
          onRequestClose={() => setopenmodal(false)}
          style={{
            overlay: {
              zIndex: 9,
              backgroundColor: "#00000063",
              position: "fixed",
            },
            content: {
              minWidth: "200px",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%,-50%)",
            },
          }}
          shouldCloseOnOverlayClick={true}
        // style={{
        //   overlay: {
        //     width: 650,
        //     height: 400,
        //     borderRadius: "10px",
        //     backgroundColor: "black",
        //     zIndex: "1000",
        //     top: "50%",
        //     left: "50%",
        //     marginTop: "-300px",
        //     marginLeft: "-350px",
        //   },
        // }}
        >
          <div className="modal-header">

            <h4 className="modal-title">Are You Sure?</h4>
          </div>
          <div className="modal-body">
            <div className="md-form">
              <i className="fas fa-pencil prefix grey-text"></i>
              <label
                data-error="wrong"
                name="cancel_reason"
                data-success="right"
                value
                for="form371</label>"
              >
                Reason For Cancel
              </label>
              <textarea
                type="text"
                name="cancel_reason"
                value={reason}
                onChange={(e) => setreason(e.target.value)}
                id="form371"
                className="md-textarea form-control"
                rows="4"
              ></textarea>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dafault"
              onClick={() => setopenmodal(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={(e) => handleCancelRide(e)}
            >
              Cancel Trip
            </button>
          </div>
        </Modal>
      </div>{" "}
      <LogoutModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleLogoutSubmit}
      />{" "}
    </div>
  );
};
export default BookingHistory;
