import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { KioskSMS } from "../../utils/Endpoint";
import ApiService from "../../utils/Api";
import { toast } from "react-toastify";

export const SendMessage = ({ closeDetailHandler, otp }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(""); // To store country code
  const [errors, setErrors] = useState({});
  const apiKey = sessionStorage.getItem("key");

  const handleClearName = () => {
    setName("");
    if (errors.Phone) setErrors((prev) => ({ ...prev, Phone: "" }));
  };

  const onPhoneChange = (phone) => {
    setPhone(phone);

    // Parse the phone number to get the country code
    const parsedNumber = parsePhoneNumberFromString(`+${phone}`);
    if (parsedNumber && parsedNumber.countryCallingCode) {
      setCountryCode(parsedNumber.countryCallingCode);
    }
  };

  const sendSMSHandler = async (event) => {
    event.preventDefault();

    if (!phone) {
      toast.error("Please enter your phone number");
      return;
    }

    const params = {
      lang: "en",
      msg: 1232,
      otp: otp,
      ph: phone,
      c_code: countryCode,
    };

    try {
      const response = await ApiService.post(`${KioskSMS}?`, params);

      if (response.status === 200) {
        // toast.success(
        //   "Please check if your WhatsApp details have been sent successfully."
        // );
        closeDetailHandler();
      }
    } catch (error) {
      console.error("Try again", error);
      toast.error("Failed to send message. Please try again.");
    }

    const data = {
      lang: "ar",
      msg: 1232,
      otp: otp,
      ph: phone,
      c_code: countryCode,
    };

    try {
      const response = await ApiService.post(`${KioskSMS}?`, data);

      if (response.status === 200) {
        toast.success(
          "Please check your WhatsApp details have been sent successfully."
        );
        closeDetailHandler();
      }
    } catch (error) {
      console.error("Try again", error);
      toast.error("Failed to send message. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <div className="backdrop">
        <div
          className="center-screen"
          style={{ position: "absolute", minWidth: "375px" }}
        >
          <div
            className="col-md-auto col-sm-auto form-group"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <button
              className="book-btn"
              style={{
                borderRadius: "50%",
                fontSize: 18,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 10,
                right: 10,
                height: 40,
                width: 40,
                textAlign: "center",
              }}
              onClick={() => closeDetailHandler()}
            >
              x
            </button>
          </div>
          <div className="offercount">
            <div
              style={{
                textAlign: "center",
                color: "black",
                marginBottom: 20,
              }}
            >
              <h3>Send to WhatsApp</h3>
            </div>
            <div style={{ display: "grid" }}>
              <div
                className="trip-details-container"
                style={{ display: "grid", position: "relative" }}
              >
                <form onSubmit={sendSMSHandler}>
                  {/* <div className="col-md-auto col-sm-auto">
                    <strong className="location-label">
                      <span>Name / الاسم</span>
                    </strong>
                    <div className="field-holder field-holderb border">
                      <input
                        type="text"
                        placeholder="Please Enter Your Name"
                        className="location-search-input"
                        value={name.address}
                        onChange={(e) =>
                          setName( e.target.value)
                        }
                      />
                      {name.address && (
                        <span
                          className="fas fa-times-circle"
                          style={{
                            marginRight: "0px",
                            fontSize: "17px",
                            cursor: "pointer",
                          }}
                          onClick={handleClearName}
                        ></span>
                      )}
                    </div>
                  </div> */}

                  <div className="col-md-auto col-sm-auto">
                    <strong className="location-label">
                      <span>Phone / الهاتف</span>
                    </strong>
                    <div className="field-holder ph field-holderb border">
                      <PhoneInput
                        country={"om"} // Sets Oman as the default country
                        value={phone}
                        onChange={onPhoneChange}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        inputClass="location-search-input"
                      />
                    </div>
                  </div>
                  <div className="col-md-auto col-sm-auto form-group">
                    <button
                      className="book-btn"
                      style={{ width: "100%", marginTop: "7px" }}
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
